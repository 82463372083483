import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomUserDetailsRead, useAuthUserPartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL_FIELD, FIRSTNAME_FIELD, LASTNAME_FIELD } from '@pages/Settings/constants';
import { schema } from '@pages/Settings/schema';
import { PersonalDetailsSchema } from '@pages/Settings/types';
import { toggleEdit } from '@store/settingsSlice';
import { RootState } from '@store/store';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { SettingsFieldName } from '@/globalTypes';

const fieldMap: Record<string, SettingsFieldName> = {
  isEditFirstName: FIRSTNAME_FIELD,
  isEditLastName: LASTNAME_FIELD,
  isEditEmail: EMAIL_FIELD,
};

export const useUpdatePersonalDetails = (userData?: CustomUserDetailsRead) => {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const personalDetailsState = useSelector((state: RootState) => state.settings);
  const [updateUserMutation, { isLoading: isLoadingPartialUpdateUser }] = useAuthUserPartialUpdateMutation();

  const personalDetailsForm = useForm<PersonalDetailsSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: userData?.first_name || '',
      lastName: userData?.last_name || '',
      email: userData?.email || '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const handleToggleOffField = useCallback(() => {
    const truthyField = Object.keys(personalDetailsState).find(
      key => personalDetailsState[key as keyof typeof personalDetailsState],
    );
    const fieldName = fieldMap[truthyField as keyof typeof personalDetailsState];
    if (fieldName) {
      dispatch(toggleEdit(fieldName));
    }
  }, [dispatch, personalDetailsState]);

  const handleSubmitPersonalDetails = useCallback(
    async ({ firstName, lastName, email }: PersonalDetailsSchema) => {
      try {
        await updateUserMutation({
          patchedCustomUserDetailsRequest: {
            ...(firstName && firstName !== userData?.first_name && { first_name: firstName }),
            ...(lastName && lastName !== userData?.last_name && { last_name: lastName }),
            ...(email && email !== userData?.email && { email }),
          },
        });
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      } finally {
        handleToggleOffField();
      }
    },
    [handleToggleOffField, snackbar, updateUserMutation, userData?.email, userData?.first_name, userData?.last_name],
  );

  return {
    personalDetailsForm,
    handleSubmitPersonalDetails,
    isLoadingPartialUpdateUser,
  };
};
