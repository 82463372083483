import { useCallback } from 'react';
import { useAuthUserParticipantsListQuery } from '@api/api';
import NiceModal from '@ebay/nice-modal-react';
import { AddParticipantModalId } from '@pages/Settings/modals/AddParticipant';
import { EditParticipantNameModalId } from '@pages/Settings/modals/EditParticipantName';

export const useParticipants = () => {
  const { data: participantsData, isLoading: isLoadingParticipantsData } = useAuthUserParticipantsListQuery();

  const handleShowEditModal = useCallback(
    ({ id, firstName, lastName }: { id: number; firstName: string; lastName: string }) =>
      NiceModal.show(EditParticipantNameModalId, { id, firstName, lastName }),
    [],
  );
  const handleShowAddParticipantModal = useCallback(
    (addAsParticipant: boolean) => NiceModal.show(AddParticipantModalId, { addAsParticipant }),
    [],
  );

  return {
    participantsData,
    isLoadingParticipantsData,
    handleShowEditModal,
    handleShowAddParticipantModal,
  };
};
