import { FC, memo, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Participant from '@pages/Relationship/components/Participant';
import {
  INITIAL_PARTICIPANT,
  NOMINEE_OR_CHILD_REPRESENTATIVE_CHECKBOX,
  PARTICIPANT_CHECKBOX,
  REFUSE_CHECKBOX,
} from '@pages/Relationship/constants';
import { StyledCheckboxFormControlLabel, StyledTextButton } from '@pages/Relationship/styled';
import { ParticipantData } from '@pages/Relationship/types';
import { StyledBox, StyledPaper } from '@pages/styled';

import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Typography } from '@mui/material';
import RegistrationHeader from '@components/RegistrationHeader';

const RelationshipForm: FC<{ loading?: boolean; showEditState?: boolean; handleBack: () => void }> = ({
  loading,
  showEditState,
  handleBack,
}) => {
  const { t } = useTranslation();
  const { control, reset } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
    keyName: 'fieldId',
  });

  const [isParticipant, isNomineeRepresentative, names, isRefuse] = useWatch({
    control,
    name: [PARTICIPANT_CHECKBOX, NOMINEE_OR_CHILD_REPRESENTATIVE_CHECKBOX, 'data', 'refuse'],
  });

  const participantsLimit = isParticipant && isNomineeRepresentative ? 1 : 2;

  const isCheckboxSelected = [isParticipant, isNomineeRepresentative, isRefuse].some(Boolean);

  const isMaxParticipantsReached = fields.length >= participantsLimit;
  const areParticipantsFilled = isNomineeRepresentative
    ? names.every((n: ParticipantData) => n.first_name.length > 0 && n.last_name.length > 0)
    : true;

  useEffect(() => {
    if (fields.length > participantsLimit && participantsLimit >= 2) {
      remove(fields.length - 1);
    }
  }, [fields.length, participantsLimit, remove, reset]);

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" aria-label="NDIS relationship form">
      <RegistrationHeader />

      <StyledPaper
        elevation={6}
        sx={{
          p: '40px 30px',
          width: {
            sm: '560px',
            md: '550px',
            lg: '505px',
          },
        }}
      >
        <StyledBox>
          {!showEditState && (
            <>
              <Typography fontSize={20} fontFamily="WFVisualSans" mb={3}>
                {t('auth.ndis.formTitle')}
              </Typography>
              <Typography fontSize={14} mb={2}>
                {t('auth.ndis.formSubtitle')}
              </Typography>

              <Box>
                <Typography fontWeight={400} fontSize={14} mb={1} color="textSecondary">
                  {t('auth.ndis.checkboxTitle')}
                </Typography>
                {/* Checkboxes */}
                <Box display="flex" flexDirection="column" gap={1}>
                  <StyledCheckboxFormControlLabel
                    control={
                      <Controller
                        control={control}
                        name={PARTICIPANT_CHECKBOX}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    labelPlacement="end"
                    label={t('auth.ndis.participant')}
                  />
                  <StyledCheckboxFormControlLabel
                    control={
                      <Controller
                        control={control}
                        name={NOMINEE_OR_CHILD_REPRESENTATIVE_CHECKBOX}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    labelPlacement="end"
                    label={t('auth.ndis.representative')}
                  />
                </Box>
              </Box>
            </>
          )}

          {showEditState && (
            <Box>
              <StyledTextButton onClick={handleBack} startIcon={<ChevronLeftIcon color="primary" />}>
                <Typography fontSize={13} color="primary">
                  {t('common.back')}
                </Typography>
              </StyledTextButton>
              <Typography fontSize={20} fontFamily="WFVisualSans" mt="12px" mb={3}>
                {t('auth.ndis.participantName')}
              </Typography>
              {fields.map((field, index) => (
                <Box key={field.fieldId} my={index > 0 ? 3 : 0} mb={index === fields.length - 1 ? 0 : 3}>
                  <Participant
                    id={field.fieldId}
                    index={index}
                    disabled={isRefuse}
                    title={
                      isParticipant && isNomineeRepresentative
                        ? 'auth.ndis.enterParticipantYouRepresent'
                        : index < 1
                          ? 'auth.ndis.enterParticipantName'
                          : 'auth.ndis.enterOtherParticipantName'
                    }
                  />
                </Box>
              ))}

              <Box mt={isMaxParticipantsReached ? 1 : 2}>
                {isMaxParticipantsReached && !isParticipant && (
                  <StyledTextButton
                    variant="text"
                    type="button"
                    onClick={() => {
                      remove(1);
                    }}
                  >
                    <Typography color="primary">{t('auth.ndis.remove')}</Typography>
                  </StyledTextButton>
                )}
                {!isMaxParticipantsReached && (
                  <StyledTextButton
                    variant="text"
                    type="button"
                    onClick={() => {
                      append({ ...INITIAL_PARTICIPANT });
                    }}
                    startIcon={<AddIcon color="primary" />}
                  >
                    <Typography color="primary">{t('auth.ndis.add')}</Typography>
                  </StyledTextButton>
                )}
                {isParticipant && isNomineeRepresentative && (
                  <Box mt={3}>
                    <StyledCheckboxFormControlLabel
                      control={
                        <Controller
                          control={control}
                          name={REFUSE_CHECKBOX}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      labelPlacement="end"
                      label={t('auth.ndis.refuse')}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <LoadingButton
            fullWidth
            disabled={!isCheckboxSelected || (showEditState && !areParticipantsFilled && !isRefuse)}
            variant="contained"
            color="primary"
            loading={loading}
            aria-label="submit ndis relation button"
            type="submit"
            sx={{ mt: 5 }}
          >
            {showEditState ? t('auth.ndis.createAccount') : t('auth.continue')}
          </LoadingButton>
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(RelationshipForm);
