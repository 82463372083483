import { ParticipantData } from '@pages/Relationship/types';

export const INITIAL_PARTICIPANT: ParticipantData = {
  first_name: '',
  last_name: '',
};

export const PARTICIPANT_FORM_SHAPE: ParticipantData[] = [INITIAL_PARTICIPANT];

export const DEFAULT_FORM_VALUES = {
  data: PARTICIPANT_FORM_SHAPE,
  participant: false,
  nomineeOrChildRepresentative: false,
  refuse: false,
};

export const PARTICIPANT_CHECKBOX = 'participant';
export const NOMINEE_OR_CHILD_REPRESENTATIVE_CHECKBOX = 'nomineeOrChildRepresentative';
export const REFUSE_CHECKBOX = 'refuse';
