import { TimelineConnector, TimelineDot, timelineItemClasses } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import { Alert, AlertProps, Box, BoxProps, Chip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAlert = styled(Alert)<AlertProps>(() => ({
  marginBottom: '36px',
  borderColor: '#0588D1',
  maxWidth: '1040px',
}));

export const StyledEventsPaymentDueChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-label': { color: theme.colors.contrast },
}));

export const StyledTimelineDot = styled(TimelineDot)({
  backgroundColor: '#F4ECFF',
});

export const StyledTimelineConnector = styled(TimelineConnector)({
  backgroundColor: '#F4ECFF',
  width: '5px',
  borderRadius: '5px',
});

export const StyledTimeline = styled(Timeline)({
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  padding: 0,
});

export const StyledTimelineActionRow = styled(Box)<BoxProps & { isChecked?: boolean }>(({ theme, isChecked }) => ({
  backgroundColor: isChecked ? theme.colors.background.detailsBackground : 'transparent',
  borderTop: '1px solid #E0E0E0',
  padding: '8px 10px',
}));

export const StyledSecondaryDueDateChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.colors.secondary.contrast,
  },
}));
