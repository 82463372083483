import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import AccountTab from '@pages/Settings/components/AccountTab';
import BillingTab from '@pages/Settings/components/BillingTab';
import SettingsHeader from '@pages/Settings/components/SettingsHeader';
import { SettingsTab } from '@pages/Settings/constants/enums';
import { useSettingsTab } from '@pages/Settings/hooks/useSettingsTab';

import { Box } from '@mui/material';

const Settings: FC = () => {
  const { t } = useTranslation();
  const { activeTab, handleTabChange } = useSettingsTab();

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.accountSettings')}</title>
      </Helmet>

      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <SettingsHeader handleTabChange={handleTabChange} activeTab={activeTab} />
        {activeTab === SettingsTab.Account && <AccountTab />}
        {activeTab === SettingsTab.Billing && <BillingTab />}
      </Box>
    </>
  );
};

export default memo(Settings);
