import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';

import { Box } from '@mui/material';

const ParticipantGuard: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, isLoading } = useUser();

  if (isLoading) return null;

  const hasParticipants = Array.isArray(user?.participants) && user.participants.length > 0;

  if (hasParticipants) return <Navigate to={ROUTING.ROOT} replace />;

  return <Box style={hasParticipants ? { visibility: 'hidden' } : undefined}>{!hasParticipants ? children : null}</Box>;
};

export default ParticipantGuard;
