import { COUNTDOWN_DATE } from '@constants/common';
import { addSeconds, endOfMonth, format, formatISO, parseISO, startOfMonth } from 'date-fns';

export const formatCountdownTime = (countdown: number, message: string) => {
  if (countdown === 0) {
    return '0';
  }

  const endDate = addSeconds(COUNTDOWN_DATE, countdown);
  const formattedCountdown = format(endDate, 'mm:ss');

  return `(${message} ${formattedCountdown})`;
};

type Month = 'MM' | 'MMM' | 'MMMM';
type Day = 'd' | 'dd';

export const convertIsoToReadable = (
  isoDate?: string | Date,
  options?: { fullYear?: boolean; withComma?: boolean; monthFormat?: Month; dayFormat?: Day },
) => {
  if (!isoDate) return '';

  const { fullYear = false, withComma = false, monthFormat = 'MMM', dayFormat = 'dd' } = options || {};
  const dateObject = typeof isoDate === 'string' ? parseISO(isoDate) : isoDate;

  return format(dateObject, `${dayFormat} ${monthFormat}${withComma ? ',' : ''} ${fullYear ? 'yyyy' : 'yy'}`);
};

export const convertIsoToReadableWithTime = (isoDate?: string) => {
  if (!isoDate) return '';
  const dateObject = parseISO(isoDate);
  return format(dateObject, 'dd MMM yyyy, h:mma').replace('AM', 'am').replace('PM', 'pm');
};

export function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export const formatMonthYear = (isoString: string): string => {
  const date = parseISO(isoString);
  return format(date, 'MMMM yyyy');
};

/**
 * Returns the ISO string representing the start of the month for the given date.
 * @param selectedMonth - ISO string of a date within the month (e.g., '2024-12-01').
 * @returns ISO string representing the start of the month (e.g., '2024-12-01').
 */
export const getStartOfMonth = (selectedMonth: string): string => {
  const date = parseISO(selectedMonth);
  return formatISO(startOfMonth(date), { representation: 'date' });
};

/**
 * Returns the ISO string representing the end of the month for the given date.
 * @param selectedMonth - ISO string of a date within the month (e.g., '2024-12-01').
 * @returns ISO string representing the end of the month (e.g., '2024-12-31').
 */
export const getEndOfMonth = (selectedMonth: string): string => {
  const date = parseISO(selectedMonth);
  return formatISO(endOfMonth(date), { representation: 'date' });
};

export const getDay = (date: string, dayFormat: Day): string => {
  const parsedDate = parseISO(date);
  const dayOfWeek = format(parsedDate, dayFormat === 'd' ? 'EEE' : 'EEEE');
  return dayOfWeek.toUpperCase();
};

export const getDayOfMonth = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'dd');
};

export const getMonthFull = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MMMM');
};
