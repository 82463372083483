import { Box, BoxProps, Button, ButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.colors.primary.main,
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-root.Mui-focusVisible': {
    boxShadow: 'none',
  },
}));

export const StyledSaveButton = styled(Button)<ButtonProps>({
  maxWidth: 68,
  height: 36,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
});

export const StyledErrorTextButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.colors.error.main,
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-root.Mui-focusVisible': {
    boxShadow: 'none',
  },
}));

export const StyledAccountTypography = styled(Typography)({
  display: 'block',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
});

export const StyledParticipantInfoBox = styled(Box)<BoxProps>(() => ({
  height: '90px',
}));
