import { ChangeEvent, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthUserParticipantsCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '@hooks/api/useUser';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { ParticipantRole } from '@pages/Settings/modals/AddParticipant/enums';
import FormView from '@pages/Settings/modals/AddParticipant/FormView';
import RadioOptionsView from '@pages/Settings/modals/AddParticipant/RadioOptionsView';
import { addAnotherParticipantSchema } from '@pages/Settings/schema';
import { StyledIconButton, StyledModal } from '@pages/Settings/styled';
import { AddAnotherParticipantSchema } from '@pages/Settings/types';
import { getRoleType } from '@pages/Settings/utils/getRoleType';
import { getErrorKeys, getErrorText } from '@utils/getError';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

import { ErrorObj } from '@/shared/types';

const AddParticipantModal = NiceModal.create(({ addAsParticipant }: { addAsParticipant: boolean }) => {
  const initialRadioValue = addAsParticipant ? ParticipantRole.Myself : ParticipantRole.Nominee;
  const [value, setValue] = useState<ParticipantRole>(initialRadioValue);
  const [isFormView, setIsFormView] = useState<boolean>(false);
  const { t } = useTranslation();
  const modal = useModal();
  const snackbar = useSnackbar();
  const { downSm } = useBreakpoints();
  const { userFullName, userLastName, userName, invoiceEmail } = useUser();

  const [addParticipantMutation, { isLoading }] = useAuthUserParticipantsCreateMutation();

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as ParticipantRole);
  };

  const handleNext = () => setIsFormView(true);

  const addAnotherParticipantForm = useForm<AddAnotherParticipantSchema>({
    resolver: yupResolver(addAnotherParticipantSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const handleConfirmClick: SubmitHandler<AddAnotherParticipantSchema> = async ({ first_name, last_name }) => {
    try {
      if (addAsParticipant) {
        await addParticipantMutation({
          participantRequest: {
            type: 'user',
            first_name: userName,
            last_name: userLastName,
            is_active: true,
          },
        });
        return;
      }

      await addParticipantMutation({
        participantRequest: {
          type: getRoleType(value),
          first_name: first_name as string,
          last_name: last_name as string,
          is_active: true,
        },
      });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, getErrorText(getErrorKeys(err as ErrorObj))), { variant: ERROR });
    } finally {
      modal.resolve(true);
      modal.remove();
    }
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <Box padding="15px 25px">
        <Box textAlign="end">
          <StyledIconButton disableRipple onClick={handleCancelClick}>
            <CloseIcon />
          </StyledIconButton>
        </Box>
        <Typography fontSize={20} fontFamily="WFVisualSans">
          {t('settings.modal.addParticipant.title')}
        </Typography>

        <Box mt={3}>
          {!isFormView && (
            <RadioOptionsView
              onChange={handleChangeRadio}
              handleCancel={handleCancelClick}
              value={value}
              handleNext={handleNext}
            />
          )}

          {isFormView && (
            <FormProvider {...addAnotherParticipantForm}>
              <form
                autoComplete="off"
                onSubmit={addAnotherParticipantForm.handleSubmit(handleConfirmClick, err => console.log(err))}
              >
                <FormView
                  invoiceEmail={invoiceEmail}
                  value={value}
                  userFullName={userFullName}
                  isLoading={isLoading}
                  handleCancelClick={handleCancelClick}
                />
              </form>
            </FormProvider>
          )}
        </Box>
      </Box>
    </StyledModal>
  );
});

export const AddParticipantModalId = 'AddParticipantModalModal';

NiceModal.register(AddParticipantModalId, AddParticipantModal);
