import { ChangeEvent, FC, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { ParticipantRole } from '@pages/Settings/modals/AddParticipant/enums';
import { StyledConfirmCheckbox, StyledParticipantAlert, StyledTextField } from '@pages/Settings/styled';
import { StyledRegularTextField } from '@pages/styled';

import { Box, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

interface FormViewProps {
  invoiceEmail: string;
  value: ParticipantRole;
  userFullName: string;
  isLoading: boolean;
  handleCancelClick: () => void;
}

const FormView: FC<FormViewProps> = ({ invoiceEmail, value, userFullName, isLoading, handleCancelClick }) => {
  const [confirmCheckbox, setConfirmCheckbox] = useState<boolean>(false);
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const firstNameField = watch('first_name');
  const lastNameField = watch('last_name');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmCheckbox(event.target.checked);
  };

  return (
    <Box>
      <StyledParticipantAlert variant="filled" severity="info">
        <Trans
          i18nKey="settings.participants.modal.infoAddNotificationText"
          values={{
            invoiceEmail,
          }}
          components={{
            bold: <strong />,
          }}
        />
      </StyledParticipantAlert>

      <Box mt={value === ParticipantRole.Myself ? 4 : 3}>
        {value === ParticipantRole.Myself && (
          <>
            <Typography fontSize={14} fontWeight={400} maxWidth={440}>
              {t('settings.participants.modal.addingYourself')}
            </Typography>
            <Box mt={3}>
              <StyledRegularTextField
                fullWidth
                disabled
                variant="outlined"
                label={t('settings.participants.participantName')}
                value={userFullName}
              />
            </Box>
          </>
        )}
        {/* SHOW OTHER FORM */}
        {value !== ParticipantRole.Myself && (
          <>
            <Box width="100%">
              <Box>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      label={t('common.inputs.firstName')}
                      type="text"
                      autoComplete="off"
                      error={Boolean(fieldState.error)}
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box mt={2}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      label={t('common.inputs.lastName')}
                      type="text"
                      autoComplete="off"
                      error={Boolean(fieldState.error)}
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box width="100%" display="flex" mt={5}>
              <Box width="42px">
                <StyledConfirmCheckbox checked={confirmCheckbox} onChange={handleChange} />
              </Box>
              <Box width="100%" flexGrow={1}>
                <Typography fontSize={12} fontWeight={400}>
                  {t('settings.modal.addParticipant.checkboxLabel')}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box mt={value === ParticipantRole.Myself ? 7 : 4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StyledTextButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleCancelClick}
            variant="text"
          >
            <Typography fontSize={14}>{t('common.cancel')}</Typography>
          </StyledTextButton>
          <LoadingButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            loading={isLoading}
            color="primary"
            type="submit"
            sx={{ maxHeight: '38px', boxShadow: 2, padding: '6px 16px' }}
            disabled={value !== ParticipantRole.Myself ? !firstNameField || !lastNameField || !confirmCheckbox : false}
          >
            <Typography fontSize={14}>{t('settings.participants.actions.addParticipant')}</Typography>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(FormView);
