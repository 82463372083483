export const ROUTING: Record<string, string> = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  CONFIRM_EMAIL: 'confirm-email',
  ROOT: '/',
  FORGOT_PASSWORD: 'forgot-password',
  CHECK_EMAIL: 'check-email',
  RESET_PASSWORD: 'reset-password',
  INVOICE_UPLOAD: 'invoice-upload',
  CREATE_ID: 'create-id',
  TO_DO_LIST: 'to-do-list',
  CLAIM_WIZARD: 'claim-wizard',
  SUBSCRIBE: 'subscribe',
  INVOICES: 'invoices',
  SETTINGS: 'settings',
  NDIS: 'ndis',
};
