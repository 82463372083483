import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveParticipantsList from '@pages/Settings/components/AccountTab/components/Participants/ActiveParticipantsList';
import { useSettingsContext } from '@pages/Settings/context';

import { Box, Typography } from '@mui/material';

const Participants: FC = () => {
  const { t } = useTranslation();
  const { participantsData } = useSettingsContext();

  return (
    <Box>
      <Typography fontSize={20} fontFamily="WFVisualSans">
        {t('settings.participants.title')}
      </Typography>
      <ActiveParticipantsList data={participantsData} />
    </Box>
  );
};

export default memo(Participants);
