import { FC, memo, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import LogoWhiteSmall from '@components/LogoWhiteSmall';

import { StyledWrapperContent } from './styled';

const RegistrationView: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      background: 'linear-gradient(to top,#FDC15E1F, #F5A57540, #ED898B80, #A354AFBF, #8435E9, #5A1ED3)',
      paddingTop: {
        xs: '58px',
      },
      paddingLeft: {
        xs: '16px',
        sm: '20px',
      },
      paddingRight: {
        xs: '16px',
        sm: '20px',
      },
      minHeight: '100vh',
    }}
  >
    <main aria-roledescription="registration-container">
      <StyledWrapperContent>
        <Box
          mb={{
            xs: '45px',
            sm: '47px',
            md: '54px',
            lg: '46px',
          }}
          pl={{
            md: '30px',
            lg: '82px',
          }}
        >
          <LogoWhiteSmall />
        </Box>
        {children}
      </StyledWrapperContent>
    </main>
  </Box>
);

export default memo(RegistrationView);
