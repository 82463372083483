import { Button, ButtonProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  position: 'relative',
  width: '100%',
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 400,
    position: 'absolute',
    left: '40px',
    textWrap: 'wrap',
    wordBreak: 'break-word',
    color: alpha('#000', 0.87),
  },
});

export const StyledTextButton = styled(Button)<ButtonProps>(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
