import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const BillingTab: FC = () => {
  const { t } = useTranslation();

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Typography color="primary" variant="h2">
        {t('common.comingSoon')}
      </Typography>
    </Box>
  );
};

export default memo(BillingTab);
