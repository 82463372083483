import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_BAR_HEIGHT } from '@constants/common';
import { CONFIG } from '@constants/config';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { useUser } from '@hooks/api/useUser';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useLogout } from '@hooks/useLogout';
import { useMenuActions } from '@hooks/useMenuActions';
import NavItem from '@providers/layouts/AppLayout/components/NavItem';
import { FeedbackModalId } from '@providers/layouts/AppLayout/modals/FeedbackModal';
import { InviteModalId } from '@providers/layouts/AppLayout/modals/InviteModal';
import {
  StyledLink,
  StyledMenuItem,
  StyledPrimaryAvatar,
  StyledReadyToClaimButton,
  StyledShareBox,
  StyledTransitionBox,
} from '@providers/layouts/AppLayout/styled';
import { useSnackbar } from 'notistack';

import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { Divider, Menu, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import LogoIcon from '@components/LogoIcon';
import LogoIconSmall from '@components/LogoIconSmall';
import LogoSmall from '@components/LogoSmall';

import { navConfig } from '../config/navigation';

import { StyledWrapTypography } from '@/shared/styles';

const footerLinks = [
  { id: 1, locale: 'dashboard.navigation.links.privacy', href: `${CONFIG.domain}${CONFIG.pages.privacy}` },
  { id: 2, locale: 'dashboard.navigation.links.termsOfUse', href: `${CONFIG.domain}${CONFIG.pages.termsOfService}` },
  { id: 3, locale: 'dashboard.navigation.links.copyright', href: `${CONFIG.domain}${CONFIG.pages.copyright}` },
];

const Navigation: FC = () => {
  const { data: userData, userFullName, invoiceEmail } = useUser();
  const { logout } = useLogout();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { anchorEl, open, handleOpen, handleClose } = useMenuActions();

  const { upLg, upMd, downSm, downMd } = useBreakpoints();

  const handleShareId = () => {
    navigator.clipboard.writeText(invoiceEmail);
    snackbar.enqueueSnackbar(t('dashboard.shareSuccess'));
  };

  const handleRefer = async () => {
    await NiceModal.show(InviteModalId, { userId: userData?.pk });
    handleClose();
  };

  const handleFeedback = async () => {
    await NiceModal.show(FeedbackModalId);
    //  ToDo add api call later when it's implemented
    handleClose();
  };

  return (
    <StyledTransitionBox
      aria-label="app navigation bar"
      aria-roledescription="navigation bar to traverse between pages"
      role="navigation"
      p={upMd ? '30px 80px' : '30px 20px'}
      px={upLg ? '80px' : downMd ? '20px' : '30px'}
      height={APP_BAR_HEIGHT}
      sx={{
        boxShadow: '0px 3px 5px -1px #00000033, 0px 6px 10px 0px #00000024, 0px 1px 1px 0px #0000001F',
      }}
    >
      <Box
        display="flex"
        aria-label="navigation scrollbar"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box height={40} display="flex" alignItems="center" gap={downSm ? 0 : 3.5}>
          {!downSm && <LogoSmall aria-label="company logo with name" />}
          {downSm && <LogoIcon aria-label="company logo without name" />}

          <Stack direction="row" spacing={2.5} aria-label="list of pages" role="menu" alignItems="center">
            {navConfig.map(item => (
              <NavItem key={item.title} item={item} />
            ))}
          </Stack>
        </Box>

        <Box display="flex" gap={3} alignItems="center">
          {upLg && (
            <StyledReadyToClaimButton variant="outlined" color="primary" size="large">
              <Typography fontSize={16} fontWeight={500}>
                Ready to claim
              </Typography>
            </StyledReadyToClaimButton>
          )}

          <Box display="flex" alignItems="center" textAlign="center">
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleOpen}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <StyledPrimaryAvatar
                  alt="Avatar"
                  variant="circular"
                  onClick={handleOpen}
                  aria-controls={open ? 'user-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  {userData?.first_name?.[0]?.toUpperCase() || <PersonIcon />}
                </StyledPrimaryAvatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                elevation: 8,
                sx: {
                  overflow: 'auto',
                  width: '283px',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '& .MuiList-root': {
                    py: 0,
                    '& .MuiDivider-root': {
                      m: 0,
                    },
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Box p="6px 16px" onClick={handleClose}>
              <Box pt="12px" pb="12px">
                <StyledWrapTypography fontFamily="WFVisualSans" fontWeight={500} fontSize={18} mb="16px">
                  {userFullName}
                </StyledWrapTypography>

                <Typography fontSize="10px" fontWeight={400} lineHeight="26.6px" letterSpacing="1px">
                  {t('dashboard.navigation.user.capsureIdTitle')}
                </Typography>

                <Typography fontSize={14} fontWeight={400}>
                  {invoiceEmail}
                </Typography>
                <StyledShareBox pt={1} onClick={handleShareId}>
                  <Typography fontSize={13}>{t('dashboard.navigation.shareBtn')}</Typography>
                </StyledShareBox>

                <Box
                  display="flex"
                  gap={1.5}
                  mt={2.5}
                  component={Link}
                  to={`/${ROUTING.SETTINGS}`}
                  sx={{ textDecoration: 'none', color: 'black' }}
                >
                  <SettingsTwoToneIcon color="primary" />
                  <Typography fontWeight={400}>{t('dashboard.navigation.accountSettings')}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <StyledMenuItem onClick={handleClose} disableRipple>
              <Box display="flex" flexDirection="column" gap={3} py="12px" width="100%">
                <Box>
                  <StyledLink
                    display="flex"
                    gap={1.5}
                    href={`${CONFIG.domain}${CONFIG.pages.howToUse}`}
                    target="_blank"
                  >
                    <MenuBookTwoToneIcon color="primary" />
                    <Typography fontWeight={400}>{t('dashboard.navigation.howToUse')}</Typography>
                  </StyledLink>
                </Box>
                <Box>
                  <StyledLink
                    display="flex"
                    gap={1.5}
                    href={`${CONFIG.domain}${CONFIG.pages.claimWizard}`}
                    target="_blank"
                  >
                    <AutoFixHighTwoToneIcon color="primary" />
                    <Typography fontWeight={400}>{t('dashboard.navigation.claimWizard')}</Typography>
                  </StyledLink>
                </Box>
              </Box>
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem onClick={handleClose} disableRipple>
              <Box display="flex" flexDirection="column" gap={3} py="10px" width="100%">
                <Box display="flex" gap={1.5} onClick={handleRefer}>
                  <RedeemTwoToneIcon color="primary" />
                  <Typography fontWeight={400}>{t('dashboard.navigation.modal.refer')}</Typography>
                </Box>
                <Box display="flex" gap={1.5} onClick={handleFeedback}>
                  <MessageTwoToneIcon color="primary" />
                  <Typography fontWeight={400}>{t('dashboard.navigation.modal.feedback')}</Typography>
                </Box>
              </Box>
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem onClick={handleClose} disableRipple>
              <Box display="flex" flexDirection="column" gap="20px" py="12px" width="100%">
                <StyledLink href={`${CONFIG.domain}${CONFIG.pages.support}`} target="_blank">
                  <Typography fontWeight={400}>{t('dashboard.navigation.support')}</Typography>
                </StyledLink>
                <Box onClick={logout}>
                  <Typography fontWeight={400}>{t('auth.logout')}</Typography>
                </Box>
              </Box>
            </StyledMenuItem>
            <Divider />
            <Box display="flex" gap="17px" px={2} pt={2} pb={2}>
              {footerLinks.map(({ id, locale, href }) => (
                <StyledLink key={id} href={href} target="_blank">
                  <Typography fontSize={13} color="text.secondary">
                    {t(locale)}
                  </Typography>
                </StyledLink>
              ))}
              <LogoIconSmall />
            </Box>
          </Menu>
        </Box>
      </Box>
    </StyledTransitionBox>
  );
};

export default memo(Navigation);
