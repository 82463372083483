import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { usePartialUpdate } from '@hooks/api/usePartialUpdate';
import EditInvoiceDetailsForm from '@pages/InvoiceDetails/components/DetailsTab/components/EditInvoiceDetailsForm';
import InvoiceDetailsSection from '@pages/InvoiceDetails/components/DetailsTab/components/InvoiceDetailsSection';
import IssueBlock from '@pages/InvoiceDetails/components/DetailsTab/components/IssueBlock';
import {
  ABN_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  PARTICIPANT_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_EXACT_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { UpdateSchema } from '@pages/InvoiceDetails/schema/types';
import { formatDate } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box, Typography } from '@mui/material';

import { StyledSwitch, StyledSwitchFormControlLabel } from './styled';

type MissingDataFieldKeys =
  | typeof DESCRIPTION_FIELD
  | typeof CATEGORY_FIELD
  | typeof VENDOR_NAME_FIELD
  | typeof PARTICIPANT_FIELD
  | typeof SERVICE_EXACT_DATE_FIELD;

const DetailsTab: FC = () => {
  const [totalIssues, setTotalIssues] = useState(0);
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { editInvoice, toggleEdit, setEditInvoice, invoiceId, invoiceForm, invoiceData, isReason, handleResetForm } =
    useInvoiceDetailsNewContext();

  const { errors: invoiceFormErrors } = invoiceForm.formState;

  const { isLoadingPartialUpdate, partialUpdateError, updateInvoice } = usePartialUpdate(invoiceId);

  const handleSubmitUpdate: SubmitHandler<UpdateSchema> = async ({
    description,
    total_amount,
    support_more_date,
    service_start_date,
    service_end_date,
    service_exact_date,
    vendor_name,
    has_abn,
    abn,
    category,
    missing_reason,
    original_filename,
    participant,
  }) => {
    try {
      const payload = {
        ...(description && { description }),
        ...(total_amount && { total_amount: total_amount.replace(/,/g, '') }),
        ...(support_more_date &&
          service_start_date &&
          service_end_date && {
            service_start_date: formatDate(new Date(service_start_date)),
            service_end_date: formatDate(new Date(service_end_date)),
            service_exact_date: null,
          }),
        ...(!support_more_date &&
          service_exact_date && {
            service_exact_date: formatDate(new Date(service_exact_date)),
            service_start_date: null,
            service_end_date: null,
          }),
        ...(vendor_name && { vendor_name }), // provider
        ...(category && category.id && { category_id: category?.id }),
        ...(missing_reason && { reason_id: missing_reason.id }),
        ...(has_abn && abn && { abn, reason: null }),
        ...(!has_abn && !abn && { abn: null }),
        ...(original_filename && original_filename !== t('common.none') && { original_filename }),
        ...(participant && { participant }),
      };
      await updateInvoice(payload);

      if (partialUpdateError) {
        return snackbar.enqueueSnackbar(getErrorMessage(partialUpdateError, API_ERROR_MSG_PATH), { variant: ERROR });
      }

      toggleEdit(editInvoice, setEditInvoice);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const handleToggleEditMode = () => {
    toggleEdit(editInvoice, setEditInvoice);
  };

  useEffect(() => {
    const filteredFormErrors = Object.keys(invoiceFormErrors).filter(
      val => ![SERVICE_EXACT_DATE_FIELD, SERVICE_START_DATE_FIELD, SERVICE_END_DATE_FIELD, ABN_FIELD].includes(val),
    );

    const formErrorFieldsSet = new Set(filteredFormErrors);

    const missingDataFieldMap: Record<MissingDataFieldKeys, boolean> = {
      [DESCRIPTION_FIELD]: !!invoiceData?.description,
      [CATEGORY_FIELD]: !!invoiceData?.category?.name,
      [VENDOR_NAME_FIELD]: !!invoiceData?.vendor_name,
      [PARTICIPANT_FIELD]: !!invoiceData?.participant,
      [SERVICE_EXACT_DATE_FIELD]: !!invoiceData?.service_exact_date || (!!invoiceData?.service_start_date && !!invoiceData?.service_end_date),
    };

    const missingDataIssues = (Object.keys(missingDataFieldMap) as MissingDataFieldKeys[]).filter(
      field => !missingDataFieldMap[field] && !formErrorFieldsSet.has(field),
    ).length;

    const totalCount = filteredFormErrors.length + missingDataIssues;

    setTotalIssues(totalCount);
  }, [
    invoiceData?.description,
    invoiceData?.category?.name,
    invoiceData?.vendor_name,
    invoiceData?.service_exact_date,
    invoiceData?.service_start_date,
    invoiceData?.service_end_date,
    invoiceData?.abn,
    isReason,
    invoiceForm.formState.errors,
    editInvoice,
    invoiceFormErrors,
    invoiceData?.participant,
  ]);

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
    invoiceForm.trigger();
  }, [handleResetForm, invoiceData, invoiceForm]);

  return (
    <Box display="flex" flexDirection="column" gap={3} height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight={500} fontFamily="WFVisualSans">
          {editInvoice ? t('dashboard.updateDetails.editDetails') : t('dashboard.navigation.invoiceDetails')}
        </Typography>
        <StyledSwitchFormControlLabel
          control={<StyledSwitch checked={editInvoice} color="primary" onChange={handleToggleEditMode} />}
          labelPlacement="start"
          label={t('common.editInvoice')}
        />
      </Box>

      {!!totalIssues && !editInvoice && <IssueBlock count={totalIssues} />}

      {!editInvoice && <InvoiceDetailsSection />}

      {editInvoice && (
        <FormProvider {...invoiceForm}>
          <form onSubmit={invoiceForm.handleSubmit(handleSubmitUpdate)}>
            <EditInvoiceDetailsForm loading={isLoadingPartialUpdate} />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

export default memo(DetailsTab);
