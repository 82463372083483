import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledSaveButton, StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { StyledTextField } from '@pages/Settings/styled';

import { Box, Typography } from '@mui/material';

export interface EditFieldProps {
  fieldName: string;
  onClick: () => void;
  title: string;
}

const EditField: FC<EditFieldProps> = ({ fieldName, title, onClick }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box py={1}>
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => (
          <Box width="100%">
            <Box display="flex" width="100%" justifyContent="space-between">
              <Typography fontWeight={400}>{t(title)}</Typography>
              <StyledTextButton disableRipple disableFocusRipple disableTouchRipple onClick={onClick} variant="text">
                <Typography fontSize={14}>{t('common.cancel')}</Typography>
              </StyledTextButton>
            </Box>
            <Box mt={2} p={0} display="flex" gap={2} flexDirection="column" maxWidth={360}>
              <StyledTextField
                error={Boolean(fieldState.error)}
                variant="outlined"
                label={t(title)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
              <StyledSaveButton disableRipple disableFocusRipple disableTouchRipple type="submit">
                <Typography fontSize={14}>{t('common.save')}</Typography>
              </StyledSaveButton>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default memo(EditField);
