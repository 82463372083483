import { useTranslation } from 'react-i18next';
import {
  MAX_DESCRIPTION_CHARACTERS,
  MAX_FORMATTED_ABN_SYMBOLS,
  MIN_CHARACTERS,
  NOT_AVAILABLE,
} from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  StyledActionRequiredBox,
  StyledModal,
  StyledTruncatedTypographyContrast,
  StyledYellowCountBadge,
} from '@pages/Invoices/styled';
import { ExtendedInvoice } from '@pages/Invoices/types';
import { getChipColors } from '@pages/Invoices/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import StarIcon from '@mui/icons-material/Star';
import { Box, Button, Chip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledTypographyContrast } from '@/shared/styles';

const QuickInfoModal = NiceModal.create(
  ({ invoice, isLast, showChip }: { invoice: ExtendedInvoice; isLast: boolean; showChip: boolean }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = async () => {
      window.location.replace(`/${ROUTING.INVOICES}/${invoice.id}`);
      modal.resolve(true);
      modal.remove();
    };

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    const supportDate =
      !invoice.service_start_date && !invoice.service_end_date && invoice.support_date
        ? convertIsoToReadable(invoice.support_date, { fullYear: true })
        : invoice.service_start_date &&
          invoice.service_end_date &&
          `${convertIsoToReadable(invoice.service_start_date, { fullYear: true })} - ${convertIsoToReadable(invoice.service_end_date, { fullYear: true })}`;

    const missingDataCounterFields: Record<string, boolean> = {
      abn:
        (!invoice?.abn && !invoice?.reason) ||
        (!!invoice?.abn && invoice?.abn.length !== MAX_FORMATTED_ABN_SYMBOLS + MIN_CHARACTERS),
      description:
        !invoice?.description ||
        !!(invoice.description && invoice.description.length > MAX_DESCRIPTION_CHARACTERS) ||
        !!(invoice.description && invoice.description.length < MIN_CHARACTERS),
      total_amount: !invoice?.total_amount,
      category: !invoice?.category?.name,
      vendor_name:
        !invoice?.vendor_name ||
        (invoice.vendor_name && invoice.vendor_name.length > MAX_DESCRIPTION_CHARACTERS) ||
        !!(invoice.vendor_name && invoice.vendor_name.length < MIN_CHARACTERS),
      participant: !invoice?.participant,
      support_date: !invoice?.service_exact_date && !invoice?.service_start_date && !invoice?.service_end_date,
    };

    const totalIssues = Object.values(missingDataCounterFields).filter(val => val).length;

    return (
      <StyledModal disableRestoreFocus open={modal.visible} size="xs" onClose={handleCancelClick}>
        <Box padding="20px" width="100%">
          <Box position="absolute" top={10} right={10}>
            <IconButton onClick={handleCancelClick}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            {invoice.participant && showChip && (
              <Box mb={1}>
                <Chip
                  variant="filled"
                  size="medium"
                  label={
                    <Box display="flex" alignItems="center" gap="2px">
                      {isLast ? <CircleIcon fontSize="small" /> : <StarIcon fontSize="small" />}
                      <Typography fontSize={13}>{` ${invoice.participant}`}</Typography>
                    </Box>
                  }
                  sx={{
                    color: '#000000DE',
                    backgroundColor: getChipColors(isLast).backgroundColor,
                    border: `1px solid ${getChipColors(isLast).borderColor}`,
                    outline: `1px solid ${getChipColors(isLast).borderColor}`,
                    '& svg': { color: getChipColors(isLast).borderColor },
                  }}
                />
              </Box>
            )}
            <Typography fontSize={14} fontWeight={400} color="textSecondary">
              {supportDate || NOT_AVAILABLE}
            </Typography>
          </Box>

          <Box mt={1}>
            <StyledTypographyContrast fontFamily="WFVisualSans" fontSize={28} fontWeight={500}>
              {`${invoice.currency_symbol ? invoice.currency_symbol : ''}${formatCurrency(invoice.total_amount)}`}
            </StyledTypographyContrast>
          </Box>

          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <StyledTruncatedTypographyContrast fontSize={20} fontFamily="WFVisualSans" linesLimit={2}>
              {invoice.description}
            </StyledTruncatedTypographyContrast>
            <StyledTruncatedTypographyContrast fontWeight={400}>
              {invoice.vendor_name}
            </StyledTruncatedTypographyContrast>
          </Box>

          {!!totalIssues && (
            <Box my={3}>
              <StyledActionRequiredBox
                display="flex"
                gap={1}
                alignItems="center"
                width="100%"
                justifyContent="space-between"
                p="8px 10px"
              >
                <Box display="flex" gap="10px">
                  <ErrorIcon color="warning" />
                  <Typography fontFamily="WFVisualSans" color="#5F2120">
                    {t('dashboard.invoiceDetails.actionRequired')}
                  </Typography>
                </Box>
                <StyledYellowCountBadge>
                  <Typography fontSize={12} fontWeight={500} classes="action-count">
                    {totalIssues}
                  </Typography>
                </StyledYellowCountBadge>
              </StyledActionRequiredBox>
            </Box>
          )}
          <Box display="flex" mt={2.5} width="100%">
            <Button
              fullWidth
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={handleConfirmClick}
              color="primary"
            >
              <Typography fontSize={14}>{t('dashboard.quickInfoModal.viewDetailsBtnText')}</Typography>
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const QuickInfoModalId = 'QuickInfoModal';

NiceModal.register(QuickInfoModalId, QuickInfoModal);
