import { Dialog, DialogProps, IconButtonProps, TextField, TextFieldProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';

const mapSizes = {
  xs: {
    '.MuiPaper-root': {
      maxWidth: '352px',
      width: '100%',
    },
  },
  small: {
    '.MuiPaper-root': {
      maxWidth: '444px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1158px',
      height: '85vh',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'xs' | 'small' | 'medium' | 'xl'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.background.default,
      margin: '8px',
    },
    zIndex: 1800,
    ...(size && mapSizes[size]),
  }),
);

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }
  #image-renderer {
    background-image: none;
  }
  '& .react-pdf__page': {
    display: flex;
    justify-content: center;
  }
  .react-pdf__Page canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledAddModalRegularTextField = styled(TextField)<TextFieldProps>({
  '& .MuiInputBase-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
  '& .MuiFormLabel-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
  '&.MuiFormControl-root': { width: '100%' },
});

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: 0,
});
