import { FC, memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ParticipantTypeEnum, useAuthUserParticipantsCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '@hooks/api/useUser';
import { DEFAULT_FORM_VALUES } from '@pages/Relationship/constants';
import { schema } from '@pages/Relationship/schema';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import { ObjectSchema } from 'yup';

import { Box } from '@mui/material';

import RelationshipForm from './components/RelationshipForm';
import { FormFields, ParticipantData } from './types';

const Relationship: FC = () => {
  const [participantCreation, setParticipantCreation] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { isLoading: isLoadingUser, userName, userLastName } = useUser();

  const form = useForm<FormFields>({
    resolver: yupResolver(schema as ObjectSchema<FormFields>),
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const [createParticipantMutation] = useAuthUserParticipantsCreateMutation();

  const isLoading = [isLoadingUser, participantCreation].some(Boolean);

  const createParticipants = useCallback(
    async (participantsData: Array<ParticipantData>, type: ParticipantTypeEnum) => {
      const createPromises = participantsData
        .filter(p => ({ first_name: p.first_name?.trim(), last_name: p.last_name?.trim() }))
        .map(p =>
          createParticipantMutation({
            participantRequest: {
              first_name: p.first_name.trim(),
              last_name: p.last_name.trim(),
              type,
              is_active: true,
            },
          }).unwrap(),
        );

      if (createPromises.length === 0) return;

      try {
        await Promise.all(createPromises);
      } catch (error) {
        throw new Error(`Failed to create participants: ${getErrorMessage(error, API_ERROR_MSG_PATH)}`);
      }
    },
    [createParticipantMutation],
  );

  const onConfirm: SubmitHandler<FormFields> = useCallback(
    async ({ nomineeOrChildRepresentative, participant, data, refuse }) => {
      try {
        setParticipantCreation(true);

        if (nomineeOrChildRepresentative && data[0].first_name.length < 1) {
          return setShowForm(true);
        }

        setParticipantCreation(true);

        if ((participant && !nomineeOrChildRepresentative) || refuse) {
          await createParticipants([{ first_name: userName, last_name: userLastName }], 'user');
        }
        if (nomineeOrChildRepresentative && !refuse) {
          await createParticipants(data, 'nominee_child_representative');
        }

        navigate(ROUTING.ROOT);
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      } finally {
        setParticipantCreation(false);
      }
    },
    [createParticipants, navigate, snackbar, userLastName, userName],
  );

  const handleBack = useCallback(() => {
    form.reset(DEFAULT_FORM_VALUES);
    setShowForm(false);
  }, [form]);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.ndisRelationship')}</title>
      </Helmet>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        aria-label="ndis relationship page"
        pb={20}
      >
        <FormProvider {...form}>
          <Box component="form" onSubmit={form.handleSubmit(onConfirm, err => console.log(err))}>
            <RelationshipForm loading={isLoading} showEditState={showForm} handleBack={handleBack} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(Relationship);
