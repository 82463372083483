export enum SettingsTab {
  Account = 'Account',
  Billing = 'Billing',
}

export enum PersonalDetails {
  FirstName = 'First Name',
  LastName = 'Last Name',
  EmailAddress = 'Email Address',
}
