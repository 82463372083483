import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantTypeEnum } from '@api/api';
import { StyledParticipantInfoBox, StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { useParticipants } from '@pages/Settings/hooks/useParticipants';
import { StyledTypographyWrapText } from '@pages/Settings/styled';
import { getRoleLocale } from '@pages/Settings/utils/getRoleLocale';

import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography } from '@mui/material';

interface ParticipantInfoProps {
  firstName: string;
  lastName: string;
  type: ParticipantTypeEnum;
  id: number;
}

const ParticipantInfo: FC<ParticipantInfoProps> = ({ id, type, firstName, lastName }) => {
  const { t } = useTranslation();
  const { handleShowEditModal } = useParticipants();

  return (
    <StyledParticipantInfoBox display="flex" justifyContent="space-between" alignItems="center" width='100%'>
      <Box display="flex" gap={4} alignItems="center" width='100%'>
        <PersonIcon color="action" />
        <Box width='100%'>
          <StyledTypographyWrapText mb="6px" fontWeight={400}>{`${firstName} ${lastName}`}</StyledTypographyWrapText>
          <StyledTypographyWrapText color="textSecondary" fontWeight={400} fontSize={14}>
            {t(getRoleLocale(type))}
          </StyledTypographyWrapText>
        </Box>
      </Box>

      {type !== 'user' && (
        <StyledTextButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={() => handleShowEditModal({ id, firstName, lastName })}
          sx={{ minWidth: 80 }}
        >
          <Typography fontSize={13} color="primary">
            {t('settings.participants.actions.editName')}
          </Typography>
        </StyledTextButton>
      )}
    </StyledParticipantInfoBox>
  );
};

export default memo(ParticipantInfo);
