import { PaginatedInvoiceListRead } from '@api/api';
import { ExtendedInvoice } from '@pages/Invoices/types';
import { getDateRange } from '@pages/Invoices/utils';

export const useGroupedByDateInvoices = (invoicesData: PaginatedInvoiceListRead) => {
  const listToExtend = invoicesData.results.filter(
    invoice => !!invoice.service_exact_date || (!!invoice.service_start_date && invoice.service_end_date),
  );
  const listToRender = listToExtend.flatMap(invoice => {
    if (invoice.service_exact_date) {
      return [
        {
          ...invoice,
          support_date: invoice.service_exact_date,
        },
      ];
    }
    if (invoice.service_start_date && invoice.service_end_date) {
      const getRange = getDateRange(invoice.service_start_date, invoice.service_end_date);

      return getRange.map(date => ({
        ...invoice,
        support_date: date,
      }));
    }

    return [];
  });

  const groupedByDate = listToRender.reduce(
    (acc, invoice) => {
      const date = invoice.support_date;
      if (!acc[date]) acc[date] = [];
      acc[date].push(invoice);
      return acc;
    },
    {} as Record<string, ExtendedInvoice[]>,
  );

  return {
    groupedByDate,
    listToRender,
  };
};
