import { FC, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '@pages/Invoices/enums';
import {
  StyledAnchoredPaper,
  StyledChipDayOfService,
  StyledDesktopInvoiceRow,
  StyledPopper,
  StyledSemiGreyTypography,
  StyledTruncatedTypographyContrast,
} from '@pages/Invoices/styled';
import { CalendarCell, ExtendedActiveParticipant, ExtendedInvoice } from '@pages/Invoices/types';
import { getCurrentDayOfService, getFinalDayOfService } from '@pages/Invoices/utils';

import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import { Box, Fade } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface DayInvoicesPopperProps {
  invoicesForDay: ExtendedInvoice[];
  dayInvoiceAnchorEl: HTMLElement | null;
  openDayInvoice: boolean;
  cell: CalendarCell;
  handleShowQuickInfoPopper: (event: MouseEvent<any>, invoice?: ExtendedInvoice, role?: ICONS) => void;
  activeParticipants: ExtendedActiveParticipant[] | [];
  onClose: (event: MouseEvent<any>) => void;
}

const DayInvoicesPopper: FC<DayInvoicesPopperProps> = props => {
  const {
    dayInvoiceAnchorEl,
    openDayInvoice,
    cell,
    invoicesForDay,
    activeParticipants,
    handleShowQuickInfoPopper,
    onClose,
  } = props;
  const { t } = useTranslation();

  return (
    <StyledPopper open={openDayInvoice} anchorEl={dayInvoiceAnchorEl} placement="top" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <StyledAnchoredPaper elevation={6}>
            <Box padding="13px 10px">
              <Box position="absolute" top={13} right={10}>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box textAlign="center">
                <StyledSemiGreyTypography fontSize={14}>{cell.dayOfWeek}</StyledSemiGreyTypography>
                <StyledSemiGreyTypography fontWeight={400} fontSize={14}>
                  {cell.dayOfMonth}
                </StyledSemiGreyTypography>
              </Box>

              <Box mt="20px" display="flex" flexDirection="column" gap="6px">
                {invoicesForDay.map(invoice => {
                  const invoiceParticipantRole = activeParticipants.find(
                    ap => `${ap.first_name} ${ap.last_name}` === invoice.participant,
                  )?.role;

                  return (
                    <StyledDesktopInvoiceRow
                      key={invoice.invoice_id}
                      display="flex"
                      width="100%"
                      flexDirection="column"
                      role={invoiceParticipantRole}
                      onClick={e => {
                        handleShowQuickInfoPopper(e, invoice, invoiceParticipantRole);
                      }}
                    >
                      {invoice.support_date && invoice.service_start_date && invoice.service_end_date && (
                        <Box mb={1} position="relative" alignSelf="center">
                          <StyledChipDayOfService
                            label={`Day ${getCurrentDayOfService(invoice.support_date, invoice.service_start_date)} of ${getFinalDayOfService(invoice.service_start_date, invoice.service_end_date)}`}
                            color="secondary"
                            size="small"
                            variant="filled"
                          />
                        </Box>
                      )}
                      <Box display="grid" gridTemplateColumns="1fr 20px" gap="5px">
                        <StyledTruncatedTypographyContrast fontSize={13} fontWeight={400} linesLimit={2}>
                          {invoice?.description || t('common.noDescription')}
                        </StyledTruncatedTypographyContrast>
                        <Box>
                          {invoiceParticipantRole &&
                            (invoiceParticipantRole === ICONS.CIRCLE ? (
                              <CircleIcon fontSize="small" />
                            ) : (
                              <StarIcon fontSize="small" />
                            ))}
                        </Box>
                      </Box>
                    </StyledDesktopInvoiceRow>
                  );
                })}
              </Box>
            </Box>
          </StyledAnchoredPaper>
        </Fade>
      )}
    </StyledPopper>
  );
};

export default memo(DayInvoicesPopper);
