import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUserPartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import InvoiceEventTimeline from '@pages/InvoiceDetails/components/TasksTab/components/InvoiceEventTimeline';
import TimelineActions from '@pages/InvoiceDetails/components/TasksTab/components/TimelineActions';
import { useEvents } from '@pages/InvoiceDetails/components/TasksTab/hooks/useEvents';
import { StyledAlert, StyledTimeline } from '@pages/InvoiceDetails/components/TasksTab/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';

const TasksTab: FC = () => {
  const { t } = useTranslation();
  const { downMd } = useBreakpoints();
  const snackbar = useSnackbar();
  const { showEventsAlert, setShowEventsAlert, eventsList } = useInvoiceDetailsNewContext();
  const { eventsListToRender, addEvent } = useEvents(eventsList);

  const [userPartialUpdateMutation] = useAuthUserPartialUpdateMutation();

  const handleDisableNotification = async () => {
    try {
      setShowEventsAlert(false);
      await userPartialUpdateMutation({
        patchedCustomUserDetailsRequest: {
          is_events_notification_enabled: false,
        },
      });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  return (
    <Box width="100%" height="100%">
      <Typography fontFamily="WFVisualSans" fontSize={20} mb={2}>
        {t('dashboard.tasks.title')}
      </Typography>
      {showEventsAlert && (
        <StyledAlert severity="info" variant="outlined" onClose={handleDisableNotification}>
          <Typography fontSize={14} fontWeight={400}>
            {t('dashboard.tasks.alertText')}
          </Typography>
        </StyledAlert>
      )}
      <Box
        display="flex"
        flexDirection="column"
        pb={{
          xs: '120px',
          sm: '90px',
          md: '105px',
        }}
        gap={downMd ? '40px' : '30px'}
      >
        <TimelineActions eventsList={eventsList} />

        {/* TIMELINE COLUMN */}
        <Box>
          <Box mb={2} display="flex" alignItems="center" height={50}>
            <Typography fontFamily="WFVisualSans" fontSize={20}>
              {t('dashboard.tasks.timelineTitle')}
            </Typography>
          </Box>
          <StyledTimeline>
            {eventsListToRender.map((timelineEvent, idx) => (
              <InvoiceEventTimeline
                key={timelineEvent.id}
                {...timelineEvent}
                isLast={idx === eventsListToRender.length - 1}
              />
            ))}
          </StyledTimeline>
        </Box>

        <Box
          width={{
            xs: '100%',
            md: '366px',
          }}
          mt={6}
        >
          <Button
            fullWidth
            disableRipple
            disableFocusRipple
            disableTouchRipple
            endIcon={<AddIcon />}
            variant="outlined"
            onClick={() => addEvent('custom_event')}
          >
            <Typography>{t('dashboard.tasks.buttons.addToTimelineText')}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TasksTab);
