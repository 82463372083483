import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ApiInvoicesEventsListApiResponse,
  InvoiceEventRead,
  InvoiceEventTypeEnum,
  useApiInvoicesEventsCreateMutation,
  useApiInvoicesEventsDestroyMutation,
  useApiInvoicesEventsPartialUpdateMutation,
} from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import {
  ICON_TYPE,
  INVOICE_POSSIBLE_MANUAL_TASKS,
  INVOICE_TASK_DETAILS_TITLES,
  INVOICE_TASK_IS_EDITABLE,
  INVOICE_TASK_TIMELINE_TITLES,
  INVOICE_TASK_TITLES,
} from '@pages/InvoiceDetails/components/TasksTab/constants';
import { EventDetails } from '@pages/InvoiceDetails/components/TasksTab/types';
import { AddEventModalId } from '@pages/InvoiceDetails/modals/AddEventModal';
import { DeleteEventModalId } from '@pages/InvoiceDetails/modals/DeleteEventModal';
import { DeleteTaskEntryModalId } from '@pages/InvoiceDetails/modals/DeleteTaskEntryModal';
import { EditEventModalId } from '@pages/InvoiceDetails/modals/EditEventModal';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

export const useEvents = (eventsList?: ApiInvoicesEventsListApiResponse) => {
  const { invoiceId = '1' } = useParams();
  const snackbar = useSnackbar();

  const [isClaimed, setIsClaimed] = useState(!!eventsList?.find(ev => ev.type === 'claim_submitted'));
  const [isPaid, setIsPaid] = useState(!!eventsList?.find(ev => ev.type === 'invoice_paid'));

  const [createEventMutation] = useApiInvoicesEventsCreateMutation();
  const [editEventMutation] = useApiInvoicesEventsPartialUpdateMutation();
  const [deleteEventMutation, { isLoading: isLoadingDeleteMutation }] = useApiInvoicesEventsDestroyMutation();

  /**
   * Opens a modal to add a new invoice event
   *
   * @param {ManualEventActions} eventType - The type of event to be added
   *
   * @throws {Error} Throws an error if the event creation process fails
   * @example
   * // Example usage in a component
   * addEvent('claim_submitted')
   */
  const addEvent = useCallback(
    async (eventType: InvoiceEventTypeEnum) => {
      try {
        const res = await NiceModal.show(AddEventModalId, {
          eventType,
          invoiceId: +invoiceId,
          createMutation: createEventMutation,
        });
        if (res) {
          if (eventType === 'claim_submitted') setIsClaimed(true);
          if (eventType === 'invoice_paid') setIsPaid(true);
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [createEventMutation, invoiceId, snackbar],
  );

  /**
   * Opens a modal to edit an existing invoice event
   *
   * @param {Object} options - The options for editing an event
   * @param {InvoiceEventsTypeEnum} options.eventType - The type of event being edited
   * @param {number} options.eventId - The unique identifier of the event to edit
   * @param {string} [options.eventName] - Optional name of the event (only for custom events)
   * @param {string} [options.eventDate] - Optional date of the event
   * @param {string} [options.eventDetails] - Optional details or description of the event
   *
   * @throws {Error} Throws an error if the event editing process fails
   * @example
   * // Example usage in a component
   * editEvent({
   *   eventType: 'custom_event',
   *   eventId: 123,
   *   eventName: 'Updated Event',
   *   eventDate: '2024-10-31T22:00:00Z',
   *   eventDetails: 'New event details'
   * })
   */
  const editEvent = useCallback(
    async ({
      eventType,
      eventId,
      eventName,
      eventDate,
      eventDetails,
    }: {
      eventType: InvoiceEventTypeEnum;
      eventId: number;
      eventDate?: string | null;
      eventDetails?: string | null;
      eventName?: string | null;
    }) => {
      try {
        await NiceModal.show(EditEventModalId, {
          eventType,
          eventId,
          eventName,
          eventDate,
          eventDetails,
          editEventMutation,
          invoiceId,
        });
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [editEventMutation, invoiceId, snackbar],
  );

  /**
   * Deletes an invoice event using a modal dialog
   *
   * @param {Object} options - The options for deleting an event
   * @param {number} options.eventId - The unique identifier of the event to delete
   * @param {string} options.eventTitle - The title or name of the event to be deleted
   * @param {string} options.eventLabel - The label of the event / task to be deleted
   *
   * @throws {Error} Throws an error if the deletion process fails
   * @example
   * // Example usage in a component
   * deleteEvent({ eventId: 123, eventTitle: 'Custom Event', taskLabel: 'Custom Event })
   */
  const deleteTimelineEvent = useCallback(
    async ({ eventId, eventTitle, taskLabel }: { eventId: number; eventTitle: string; taskLabel: string }) => {
      try {
        await NiceModal.show(DeleteEventModalId, {
          eventTitle,
          taskLabel,
          id: eventId,
          invoiceId: +invoiceId,
          deleteMutation: deleteEventMutation,
        });
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [deleteEventMutation, invoiceId, snackbar],
  );

  const deleteTimelineEntry = useCallback(
    async ({ eventId, eventTitle, taskLabel }: { eventId: number; eventTitle: string; taskLabel: string }) => {
      try {
        const res = await NiceModal.show(DeleteTaskEntryModalId, {
          eventTitle,
          taskLabel,
          id: eventId,
          invoiceId: +invoiceId,
          deleteMutation: deleteEventMutation,
        });
        if (res) {
          if (eventTitle === 'claim_submitted') setIsClaimed(false);
          if (eventTitle === 'invoice_paid') setIsPaid(false);
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [deleteEventMutation, invoiceId, snackbar],
  );

  const missingEvents: EventDetails[] = useMemo(() => {
    if (!eventsList) return [];

    const eventTypesSet = new Set(eventsList.map(event => event.type));

    const dynamicMissingEvents = INVOICE_POSSIBLE_MANUAL_TASKS.reduce((acc, manualEvent) => {
      if (manualEvent !== 'custom_event' && !eventTypesSet.has(manualEvent as InvoiceEventTypeEnum)) {
        acc.push({
          iconType: ICON_TYPE[manualEvent as InvoiceEventTypeEnum],
          title: INVOICE_TASK_TITLES[manualEvent as InvoiceEventTypeEnum],
          onAdd: addEvent,
        });
      }
      return acc;
    }, [] as EventDetails[]);

    dynamicMissingEvents.push({
      iconType: ICON_TYPE.custom_event,
      title: INVOICE_TASK_TITLES.custom_event,
      onAdd: addEvent,
    });

    return dynamicMissingEvents;
  }, [addEvent, eventsList]);

  const getDetailsFieldTitle: (ev: InvoiceEventRead) => string | undefined = (ev: InvoiceEventRead) => {
    if (ev.type === 'invoice_uploaded' && !ev.is_manual) {
      return 'dashboard.tasks.receivedFrom';
    }

    return INVOICE_TASK_DETAILS_TITLES[ev.type];
  };

  const getTaskLabel = useCallback((ev: InvoiceEventRead) => {
    if (ev.type === 'claim_submitted') {
      return 'dashboard.tasks.checkboxes.submitClaimLabel';
    }

    if (ev.type === 'invoice_paid') {
      return 'dashboard.tasks.checkboxes.payProviderLabel';
    }

    return INVOICE_TASK_TIMELINE_TITLES[ev.type];
  }, []);

  const eventsListToRender = useMemo(
    () =>
      eventsList?.map(ev => ({
        iconType: ev.type === 'invoice_uploaded' && !ev.is_manual ? ICON_TYPE.invoice_emailed : ICON_TYPE[ev.type],
        eventDate: ev.date,
        eventTitle: ev.name, // only for custom event!
        title: ev.type !== 'payment_due_date' ? INVOICE_TASK_TIMELINE_TITLES[ev.type] : undefined,
        detailsFieldTitle: getDetailsFieldTitle(ev),
        detailsFieldDescription: ev.details,
        isSubmitted: ev.type === 'claim_submitted' && !ev.is_manual,
        chipTitle: ev.type === 'payment_due_date' ? 'dashboard.tasks.paymentDue' : undefined,
        isAutogenerated: (ev.type === 'payment_due_date' || ev.type === 'invoice_paid') && !ev.is_manual,
        isEditable: ev.type === 'claim_submitted' && !ev.is_manual ? false : INVOICE_TASK_IS_EDITABLE[ev.type],
        id: ev.id,
        loadingDelete: isLoadingDeleteMutation,
        taskLabel: getTaskLabel(ev),
      })) || [],
    [eventsList, getTaskLabel, isLoadingDeleteMutation],
  );

  return {
    missingEvents,
    eventsListToRender,
    addEvent,
    editEvent,
    deleteTimelineEvent,
    isClaimed,
    isPaid,
    deleteTimelineEntry,
  };
};
