import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';

import { Box, Typography } from '@mui/material';

export interface UserDetailProps {
  title: string;
  value: string;
  toggleEdit: () => void;
}

const UserDetail: FC<UserDetailProps> = ({ title, value, toggleEdit }) => {
  const { t } = useTranslation();

  return (
    <Box py={1} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight={400}>{t(title)}</Typography>
        <StyledTextButton disableRipple disableFocusRipple disableTouchRipple variant="text" onClick={toggleEdit}>
          <Typography fontSize={14}>{t('common.edit')}</Typography>
        </StyledTextButton>
      </Box>
      <Typography mt={1} color="textSecondary" fontSize={14} fontWeight={400}>
        {t(value)}
      </Typography>
    </Box>
  );
};

export default memo(UserDetail);
