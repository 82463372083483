import { FC, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@constants/auth';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePasswordValidation } from '@hooks/usePasswordValidation';
import { SignupSchema } from '@pages/Signup/types';
import { StyledBox, StyledLink as LinkButton, StyledLoadingButton, StyledRegularTextField } from '@pages/styled';

import { Box, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';
import PasswordValidationList from '@components/PasswordValidationList';
import RegistrationHeader from '@components/RegistrationHeader';

import { StyledLink, StyledPaper, StyledPolicyTypography } from './styled';
import { StyledTypographyContrast } from '@/shared/styles';

const SignUpForm: FC<{ loading: boolean }> = ({ loading }) => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext<SignupSchema>();
  const { passwordErrors, validatePassword } = usePasswordValidation();
  const { downSm } = useBreakpoints();

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column">
      <RegistrationHeader />
      <StyledPaper
        elevation={6}
        sx={{
          py: '30px',
          px: {
            xs: '25px',
            sm: '40px',
          },
        }}
      >
        <Typography pb={3} fontFamily="WFVisualSans" fontSize={20}>
          {t('auth.yourDetails')}
        </Typography>
        <StyledBox gap={2}>
          <Box display="flex" flexDirection={downSm ? 'column' : 'row'} gap={downSm ? 2 : 3}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <StyledRegularTextField
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.firstName')}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'firstname input field',
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                />
              )}
              name="firstname"
            />
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <StyledRegularTextField
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.surname')}
                  type="text"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'lastname input field',
                  }}
                  autoComplete="off"
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                />
              )}
              name="lastname"
            />
          </Box>
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <StyledRegularTextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.email')}
                type="email"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'email input field',
                }}
                autoComplete="off"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
            name="email"
          />
          <Controller
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <InputPassword
                fullWidth
                variant="outlined"
                label={t('common.inputs.password')}
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'password input field',
                }}
                autoComplete="off"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                onChange={e => {
                  validatePassword(e.target.value);
                  onChange(e);

                  if (!isTouched) {
                    setIsTouched(true);
                  }
                }}
                {...field}
              />
            )}
            name="password"
          />

          <PasswordValidationList errors={passwordErrors} isTouched={isTouched} />

          <StyledPolicyTypography variant="body1" mb="3px">
            <Trans
              i18nKey="common.privacyPolicyText"
              components={{
                termsOfService: <StyledLink href={TERMS_OF_SERVICE_URL} target="_blank" />,
                privacyPolicy: <StyledLink href={PRIVACY_POLICY_URL} target="_blank" />,
              }}
              values={{
                terms: t('common.documentLinks.termsOfService').toLowerCase(),
                policy: t('common.documentLinks.privacyPolicy').toLowerCase(),
              }}
            />
          </StyledPolicyTypography>

          <Box width="100%">
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
              sx={{
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important',
              }}
            >
              {t('auth.continue')}
            </StyledLoadingButton>
          </Box>
        </StyledBox>
      </StyledPaper>
      <Box mt={5} alignSelf="center" textAlign="center">
        <StyledTypographyContrast fontWeight={400} mb="6px">
          {t('auth.alreadyHaveAccount')}
        </StyledTypographyContrast>
        <LinkButton component={RouterLink} to={`/${ROUTING.LOGIN}`} replace aria-label="redirect to login button">
          <Typography>{t('common.helmetTitles.login')}</Typography>
        </LinkButton>
      </Box>
    </Box>
  );
};

export default memo(SignUpForm);
