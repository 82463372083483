import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { VIEW } from '@pages/Invoices/enums';
import { StyledControlsBox, StyledTextButton, StyledViewTabs } from '@pages/Invoices/styled';
import { ControlsBarProps } from '@pages/Invoices/types';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Tab, Typography } from '@mui/material';

const ControlsBar: FC<ControlsBarProps> = ({ view, onChange }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();
  const navigate = useNavigate();

  return (
    <StyledControlsBox
      aria-label="invoices-controls-bar"
      display="flex"
      justifyContent="space-between"
      padding={{
        xs: '18px 16px 0',
        sm: '18px 30px 0',
        md: '18px 30px 0',
        lg: '18px 80px 0',
      }}
      width="100%"
      position="sticky"
      top={100}
      zIndex={1500}
    >
      <StyledViewTabs value={view} onChange={onChange} downSm={downSm}>
        <Tab value={VIEW.TABLE} label={t('dashboard.tableView')} />
        <Tab value={VIEW.CALENDAR} label={t('dashboard.calendarView')} />
      </StyledViewTabs>
      {!downSm && (
        <StyledTextButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          endIcon={<UploadFileIcon />}
          color="primary"
          variant="text"
          onClick={() => navigate(`/${ROUTING.INVOICE_UPLOAD}`)}
          sx={{
            width: '250px',
            alignItems: 'start',
            paddingTop: '3px',
          }}
        >
          <Typography fontSize={14}>{t('dashboard.navigation.uploadInvoice')}</Typography>
        </StyledTextButton>
      )}
    </StyledControlsBox>
  );
};

export default memo(ControlsBar);
