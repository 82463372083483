import {
  Alert,
  AlertProps,
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogProps,
  IconButtonProps,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { alpha, styled, Theme } from '@mui/material/styles';

export const StyledSettingsHeaderBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.background.detailsBackground,
  zIndex: 1600,
  boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
}));

export const StyledSettingsHeaderSelectionBox = styled(Box)<BoxProps & { isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    color: isSelected ? theme.colors.primary.main : alpha('#000', 0.6),
    borderBottom: isSelected ? `2px solid ${theme.colors.primary.main}` : 'none',
    cursor: 'pointer',
  }),
);

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '& .MuiInputBase-root': {
    fontWeight: 400,
    fontSize: '16px',
    color: error ? theme.colors.error.main : theme.colors.black,
  },
  '& .MuiFormLabel-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
  '&.MuiFormControl-root': { width: '100%' },
}));

const mapSizes = {
  xs: {
    '.MuiPaper-root': {
      maxWidth: '358px',
      width: '100%',
    },
  },
  small: {
    '.MuiPaper-root': {
      maxWidth: '500px',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'xs' | 'small'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.background.default,
      margin: '8px',
    },
    zIndex: 1800,
    ...(size && mapSizes[size]),
  }),
);

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: 0,
});

export const StyledTypographyWrapText = styled(Typography)<TypographyProps>(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  wordBreak: 'break-word',
}));

const severityColorMap = {
  warning: '#EF6C00',
  info: '#0288D1',
  success: 'currentColor',
  error: 'currentColor',
};

const severityBgColorMap = {
  warning: '#FFF4E5 !important',
  info: '#E5F6FD !important',
  success: 'currentColor',
  error: 'currentColor',
};

export const StyledParticipantAlert = styled(Alert)<AlertProps>(({ theme, severity }) => ({
  backgroundColor: severity ? severityBgColorMap[severity] : 'currentColor',
  color: theme.colors.black,
  boxShadow: 'none',
  margin: '0 !important',
  '& .MuiAlert-icon': {
    color: severity ? severityColorMap[severity] : 'currentColor',
  },
  '& .MuiAlert-message': {
    fontSize: '14px',
    fontWeight: 400,
    maxWidth: '376px',
  },
}));

export const StyledConfirmCheckbox = styled(Checkbox)<CheckboxProps>({
  '&.MuiButtonBase-root': {
    paddingTop: '3px',
  },
});
