import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledModal, StyledTextButton } from '@pages/Invoices/styled';
import { formatDate } from '@utils/formatTime';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers';

const SelectDateModal = NiceModal.create(
  ({
    handleSelectDate,
    handlePickDate,
  }: {
    handleSelectDate: Dispatch<SetStateAction<string>>;
    handlePickDate: (date: string) => void;
  }) => {
    const [date, setDate] = useState<Date | null>();
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = async () => {
      if (date) {
        handleSelectDate(formatDate(date));
        handlePickDate(formatDate(date));
      }
      modal.resolve(true);
      modal.remove();
    };

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal disableRestoreFocus open={modal.visible} size="xs" onClose={handleCancelClick}>
        <Box padding="20px">
          <Box display="flex" justifyContent="space-between">
            <Typography>{t('dashboard.selectDate')}</Typography>

            <IconButton disableRipple onClick={handleCancelClick} sx={{ alignSelf: 'center' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box mt={2}>
            <DatePicker
              value={date}
              onChange={newDate => setDate(newDate)}
              label={t('dashboard.selectDate')}
              slots={{
                openPickerIcon: EventIcon,
              }}
              slotProps={{
                textField: () => ({
                  sx: {
                    '& .MuiInputBase-root': {
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                    '& .MuiFormLabel-root': {
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                    '&.MuiFormControl-root': { width: '100%' },
                  },
                  variant: 'outlined',
                  color: 'primary',
                }),
              }}
            />
          </Box>
          <Box display="flex" mt={2.5} justifyContent="end" height={36}>
            <StyledTextButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              variant="text"
              onClick={handleCancelClick}
            >
              <Typography fontSize={14}>{t('common.cancel')}</Typography>
            </StyledTextButton>
            <Button
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={handleConfirmClick}
              sx={{ maxWidth: '51px' }}
            >
              <Typography fontSize={14}>{t('common.okBtn')}</Typography>
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const SelectDateModalId = 'SelectDateModal';

NiceModal.register(SelectDateModalId, SelectDateModal);
