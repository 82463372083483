export const enum VIEW {
  TABLE = 'TABLE',
  CALENDAR = 'CALENDAR',
}

export const enum SELECTOR {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

export const enum ICONS {
  STAR = 'STAR',
  CIRCLE = 'CIRCLE',
}
