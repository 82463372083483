import {
  Box,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Dialog,
  DialogProps,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledBackButton = styled(Button)<ButtonProps>(({ theme }) => ({
  paddingLeft: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.colors.primary.main,
  boxShadow: 'none',
  height: 36,
  maxWidth: 100,
}));

export const StyledSaveLoadingButton = styled(LoadingButton)(() => ({
  padding: '8px 10px',
  '&.Mui-disabled': {
    boxShadow: 'none',
  },
}));

export const StyledEmptyStateBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  border: `1px dashed ${theme.colors.primary.main}`,
  padding: '24px 20px',
  maxWidth: '404px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
  alignItems: 'center',
}));

export const StyledAddNoteButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.colors.primary.main,
  boxShadow: 'none',
  height: 36,
}));

export const StyledNoteBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAF8FF',
  borderRadius: '5px',
  padding: '24px 20px',
  border: `1px solid ${theme.colors.primary.main}`,
  width: '100%',
}));

export const StyledNoteDateChip = styled(Chip)<ChipProps>(({ theme }) => ({
  color: theme.colors.black,
  backgroundColor: '#E8D8FF',
  '& .MuiChip-label': {
    fontSize: '13px',
    fontWeight: 500,
  },
}));

export const StyledActionsButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.colors.primary.main,
  boxShadow: 'none',
  height: 24,
  padding: 0,
}));

export const StyledActionsMenu = styled(Menu)<MenuProps>({
  paper: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1,
      '& .MuiList-root': {
        py: 0,
        width: 120,
        '& .MuiDivider-root': {
          m: 0,
        },
      },
    },
  },
});

export const StyledActionsMenuItem = styled(MenuItem)(() => ({
  height: 40,
  gap: '8px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const mapSizes = {
  small: {
    '.MuiPaper-root': {
      maxWidth: '352px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1158px',
      height: '85vh',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'small' | 'medium' | 'xl'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.background.default,
      margin: '8px',
    },
    zIndex: 1800,
    ...(size && mapSizes[size]),
  }),
);

export const StyledDeleteModalButton = styled(Button)<ButtonProps>({
  height: 42,
  width: 111,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
});

export const StyledIconButton = styled(IconButton)({
  padding: 0,
});
