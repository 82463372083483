import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledErrorTextButton } from '@pages/Settings/components/AccountTab/styled';

import { Box, Typography } from '@mui/material';

interface ManageActionProps {
  title: string;
  subtitle: string;
  onClick: (() => void) | (() => Promise<void>);
}

const ManageAction: FC<ManageActionProps> = ({ title, subtitle, onClick }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" gap={0.5}>
      <Box>
        <Typography fontWeight={400} mb={1}>
          {t(title)}
        </Typography>
        <Typography fontWeight={400} fontSize={14} color="textSecondary">
          {t(subtitle)}
        </Typography>
      </Box>
      <Box alignSelf="center" minWidth={124} textAlign="end">
        <StyledErrorTextButton disableRipple disableFocusRipple disableTouchRipple onClick={onClick} variant="text">
          <Typography fontSize={13}>{t(title)}</Typography>
        </StyledErrorTextButton>
      </Box>
    </Box>
  );
};

export default memo(ManageAction);
