import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';

import { CssBaseline, GlobalStyles, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@components/theme';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import './i18n';
import '@utils/yupValidation';
import App from './App';

Sentry.init({
  dsn: 'https://d38fc6cd0a2ae4474486932fd61a0d36@o4507962283065344.ingest.de.sentry.io/4507962287521872',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['app.dev.capsure.com.au'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={{ body: { '& canvas.hiddenCanvasElement': { display: 'none' }, '& .notistack-SnackbarContainer': { zIndex: 2400 } } }} />
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);
