import { FC, memo } from 'react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { formatMonthYear } from '@utils/formatTime';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface MonthSelectorProps {
  unformattedMonthYear: string;
  onPrev: () => void;
  onNext: () => void;
}

const MonthSelector: FC<MonthSelectorProps> = ({ unformattedMonthYear, onPrev, onNext }) => {
  const { downSm } = useBreakpoints();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={downSm ? '100%' : '250px'}>
      <IconButton
        onClick={onPrev}
        aria-label="Previous Month"
        sx={{ '&.MuiButtonBase-root': { padding: '2px', marginRight: '0px' } }}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Box width={200} flexGrow={downSm ? 1 : 0}>
        <Typography fontSize={20} textAlign="center" fontFamily="WFVisualSans" color="textSecondary">
          {formatMonthYear(unformattedMonthYear)}
        </Typography>
      </Box>

      <IconButton
        onClick={onNext}
        aria-label="Next Month"
        sx={{ '&.MuiButtonBase-root': { padding: '2px', marginLeft: '0px' } }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default memo(MonthSelector);
