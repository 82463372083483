import { emptyBaseSplitApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    apiCategoriesList: build.query<ApiCategoriesListApiResponse, ApiCategoriesListApiArg>({
      query: () => ({ url: `/api/categories/` }),
    }),
    apiHealthCheckRetrieve: build.query<ApiHealthCheckRetrieveApiResponse, ApiHealthCheckRetrieveApiArg>({
      query: () => ({ url: `/api/health-check/` }),
    }),
    apiInvoicesList: build.query<ApiInvoicesListApiResponse, ApiInvoicesListApiArg>({
      query: queryArg => ({
        url: `/api/invoices/`,
        params: {
          due_date: queryArg.dueDate,
          due_date_from: queryArg.dueDateFrom,
          due_date_to: queryArg.dueDateTo,
          limit: queryArg.limit,
          offset: queryArg.offset,
          order: queryArg.order,
          order_by: queryArg.orderBy,
          participants: queryArg.participants,
          search: queryArg.search,
          service_end_date: queryArg.serviceEndDate,
          service_exact_date: queryArg.serviceExactDate,
          service_start_date: queryArg.serviceStartDate,
        },
      }),
    }),
    apiInvoicesDownloadRetrieve: build.query<ApiInvoicesDownloadRetrieveApiResponse, ApiInvoicesDownloadRetrieveApiArg>(
      {
        query: queryArg => ({ url: `/api/invoices/${queryArg.id}/download/` }),
      },
    ),
    apiInvoicesRevertCreate: build.mutation<ApiInvoicesRevertCreateApiResponse, ApiInvoicesRevertCreateApiArg>({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.id}/revert/`,
        method: 'POST',
        body: queryArg.invoiceRevertFieldRequest,
      }),
    }),
    apiInvoicesChangesList: build.query<ApiInvoicesChangesListApiResponse, ApiInvoicesChangesListApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/changes/` }),
    }),
    apiInvoicesEventsList: build.query<ApiInvoicesEventsListApiResponse, ApiInvoicesEventsListApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/events/` }),
    }),
    apiInvoicesEventsCreate: build.mutation<ApiInvoicesEventsCreateApiResponse, ApiInvoicesEventsCreateApiArg>({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.invoiceId}/events/`,
        method: 'POST',
        body: queryArg.invoiceEventRequest,
      }),
    }),
    apiInvoicesEventsRetrieve: build.query<ApiInvoicesEventsRetrieveApiResponse, ApiInvoicesEventsRetrieveApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/events/${queryArg.id}/` }),
    }),
    apiInvoicesEventsPartialUpdate: build.mutation<
      ApiInvoicesEventsPartialUpdateApiResponse,
      ApiInvoicesEventsPartialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.invoiceId}/events/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedInvoiceEventRequest,
      }),
    }),
    apiInvoicesEventsDestroy: build.mutation<ApiInvoicesEventsDestroyApiResponse, ApiInvoicesEventsDestroyApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/events/${queryArg.id}/`, method: 'DELETE' }),
    }),
    apiInvoicesNotesList: build.query<ApiInvoicesNotesListApiResponse, ApiInvoicesNotesListApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/notes/` }),
    }),
    apiInvoicesNotesCreate: build.mutation<ApiInvoicesNotesCreateApiResponse, ApiInvoicesNotesCreateApiArg>({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.invoiceId}/notes/`,
        method: 'POST',
        body: queryArg.invoiceNoteRequest,
      }),
    }),
    apiInvoicesNotesRetrieve: build.query<ApiInvoicesNotesRetrieveApiResponse, ApiInvoicesNotesRetrieveApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/notes/${queryArg.id}/` }),
    }),
    apiInvoicesNotesPartialUpdate: build.mutation<
      ApiInvoicesNotesPartialUpdateApiResponse,
      ApiInvoicesNotesPartialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.invoiceId}/notes/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedInvoiceNoteRequest,
      }),
    }),
    apiInvoicesNotesDestroy: build.mutation<ApiInvoicesNotesDestroyApiResponse, ApiInvoicesNotesDestroyApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/notes/${queryArg.id}/`, method: 'DELETE' }),
    }),
    apiInvoicesRetrieve: build.query<ApiInvoicesRetrieveApiResponse, ApiInvoicesRetrieveApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.id}/` }),
    }),
    apiInvoicesDestroy: build.mutation<ApiInvoicesDestroyApiResponse, ApiInvoicesDestroyApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.id}/`, method: 'DELETE' }),
    }),
    apiInvoicesUpdatePartialUpdate: build.mutation<
      ApiInvoicesUpdatePartialUpdateApiResponse,
      ApiInvoicesUpdatePartialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.id}/update/`,
        method: 'PATCH',
        body: queryArg.patchedInvoiceUpdateRequest,
      }),
    }),
    apiInvoicesExportRetrieve: build.query<ApiInvoicesExportRetrieveApiResponse, ApiInvoicesExportRetrieveApiArg>({
      query: () => ({ url: `/api/invoices/export/` }),
    }),
    apiReasonsList: build.query<ApiReasonsListApiResponse, ApiReasonsListApiArg>({
      query: () => ({ url: `/api/reasons/` }),
    }),
    authPasswordChangeCreate: build.mutation<AuthPasswordChangeCreateApiResponse, AuthPasswordChangeCreateApiArg>({
      query: queryArg => ({ url: `/auth/password/change/`, method: 'POST', body: queryArg.passwordChangeRequest }),
    }),
    authPasswordResetCreate: build.mutation<AuthPasswordResetCreateApiResponse, AuthPasswordResetCreateApiArg>({
      query: queryArg => ({ url: `/auth/password/reset/`, method: 'POST', body: queryArg.passwordResetRequest }),
    }),
    authPasswordResetConfirmCreate: build.mutation<
      AuthPasswordResetConfirmCreateApiResponse,
      AuthPasswordResetConfirmCreateApiArg
    >({
      query: queryArg => ({
        url: `/auth/password/reset/confirm/`,
        method: 'POST',
        body: queryArg.passwordResetConfirmRequest,
      }),
    }),
    authSigninCreate: build.mutation<AuthSigninCreateApiResponse, AuthSigninCreateApiArg>({
      query: queryArg => ({ url: `/auth/signin/`, method: 'POST', body: queryArg.loginRequest }),
    }),
    authSignoutCreate: build.mutation<AuthSignoutCreateApiResponse, AuthSignoutCreateApiArg>({
      query: () => ({ url: `/auth/signout/`, method: 'POST' }),
    }),
    authSignupCreate: build.mutation<AuthSignupCreateApiResponse, AuthSignupCreateApiArg>({
      query: queryArg => ({ url: `/auth/signup/`, method: 'POST', body: queryArg.customRegisterRequest }),
    }),
    authSignupResendEmailCreate: build.mutation<
      AuthSignupResendEmailCreateApiResponse,
      AuthSignupResendEmailCreateApiArg
    >({
      query: queryArg => ({
        url: `/auth/signup/resend-email/`,
        method: 'POST',
        body: queryArg.resendEmailVerificationRequest,
      }),
    }),
    authSignupVerifyEmailCreate: build.mutation<
      AuthSignupVerifyEmailCreateApiResponse,
      AuthSignupVerifyEmailCreateApiArg
    >({
      query: queryArg => ({ url: `/auth/signup/verify-email/`, method: 'POST', body: queryArg.confirmEmailRequest }),
    }),
    authTokenRefreshCreate: build.mutation<AuthTokenRefreshCreateApiResponse, AuthTokenRefreshCreateApiArg>({
      query: queryArg => ({ url: `/auth/token/refresh/`, method: 'POST', body: queryArg.tokenRefreshRequest }),
    }),
    authUserRetrieve: build.query<AuthUserRetrieveApiResponse, AuthUserRetrieveApiArg>({
      query: () => ({ url: `/auth/user/` }),
    }),
    authUserUpdate: build.mutation<AuthUserUpdateApiResponse, AuthUserUpdateApiArg>({
      query: queryArg => ({ url: `/auth/user/`, method: 'PUT', body: queryArg.customUserDetailsRequest }),
    }),
    authUserPartialUpdate: build.mutation<AuthUserPartialUpdateApiResponse, AuthUserPartialUpdateApiArg>({
      query: queryArg => ({ url: `/auth/user/`, method: 'PATCH', body: queryArg.patchedCustomUserDetailsRequest }),
    }),
    authUserEmailCheckUniqueCreate: build.mutation<
      AuthUserEmailCheckUniqueCreateApiResponse,
      AuthUserEmailCheckUniqueCreateApiArg
    >({
      query: queryArg => ({
        url: `/auth/user/email/check-unique/`,
        method: 'POST',
        body: queryArg.checkUniqueEmailRequest,
      }),
    }),
    authUserParticipantsList: build.query<AuthUserParticipantsListApiResponse, AuthUserParticipantsListApiArg>({
      query: () => ({ url: `/auth/user/participants/` }),
    }),
    authUserParticipantsCreate: build.mutation<AuthUserParticipantsCreateApiResponse, AuthUserParticipantsCreateApiArg>(
      {
        query: queryArg => ({ url: `/auth/user/participants/`, method: 'POST', body: queryArg.participantRequest }),
      },
    ),
    authUserParticipantsRetrieve: build.query<
      AuthUserParticipantsRetrieveApiResponse,
      AuthUserParticipantsRetrieveApiArg
    >({
      query: queryArg => ({ url: `/auth/user/participants/${queryArg.id}/` }),
    }),
    authUserParticipantsPartialUpdate: build.mutation<
      AuthUserParticipantsPartialUpdateApiResponse,
      AuthUserParticipantsPartialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/auth/user/participants/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedParticipantRequest,
      }),
    }),
    authUserParticipantsDestroy: build.mutation<
      AuthUserParticipantsDestroyApiResponse,
      AuthUserParticipantsDestroyApiArg
    >({
      query: queryArg => ({ url: `/auth/user/participants/${queryArg.id}/`, method: 'DELETE' }),
    }),
    emailsList: build.query<EmailsListApiResponse, EmailsListApiArg>({
      query: queryArg => ({ url: `/emails/`, params: { email: queryArg.email } }),
    }),
    emailsInvoiceCreate: build.mutation<EmailsInvoiceCreateApiResponse, EmailsInvoiceCreateApiArg>({
      query: queryArg => ({ url: `/emails/invoice/`, method: 'POST', body: queryArg.invoiceEmailCreateRequest }),
    }),
    emailsInvoiceCheckUniqueCreate: build.mutation<
      EmailsInvoiceCheckUniqueCreateApiResponse,
      EmailsInvoiceCheckUniqueCreateApiArg
    >({
      query: queryArg => ({ url: `/emails/invoice/check-unique`, method: 'POST', body: queryArg.inputRequest }),
    }),
    emailsMsSubscriptionRenewRetrieve: build.query<
      EmailsMsSubscriptionRenewRetrieveApiResponse,
      EmailsMsSubscriptionRenewRetrieveApiArg
    >({
      query: () => ({ url: `/emails/ms-subscription/renew/` }),
    }),
    parserUploadCreate: build.mutation<ParserUploadCreateApiResponse, ParserUploadCreateApiArg>({
      query: queryArg => ({ url: `/parser/upload/`, method: 'POST', body: queryArg.body }),
    }),
    subscriptionsRetrieve: build.query<SubscriptionsRetrieveApiResponse, SubscriptionsRetrieveApiArg>({
      query: () => ({ url: `/subscriptions/` }),
    }),
    subscriptionsCancelCreate: build.mutation<SubscriptionsCancelCreateApiResponse, SubscriptionsCancelCreateApiArg>({
      query: () => ({ url: `/subscriptions/cancel/`, method: 'POST' }),
    }),
    subscriptionsCreateCheckoutSessionCreate: build.mutation<
      SubscriptionsCreateCheckoutSessionCreateApiResponse,
      SubscriptionsCreateCheckoutSessionCreateApiArg
    >({
      query: queryArg => ({
        url: `/subscriptions/create-checkout-session/`,
        method: 'POST',
        body: queryArg.createCheckoutSessionRequest,
      }),
    }),
    subscriptionsPortalList: build.query<SubscriptionsPortalListApiResponse, SubscriptionsPortalListApiArg>({
      query: () => ({ url: `/subscriptions/portal/` }),
    }),
    subscriptionsResumeCreate: build.mutation<SubscriptionsResumeCreateApiResponse, SubscriptionsResumeCreateApiArg>({
      query: () => ({ url: `/subscriptions/resume/`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type ApiCategoriesListApiResponse = /** status 200  */ CategoryRead[];
export type ApiCategoriesListApiArg = void;
export type ApiHealthCheckRetrieveApiResponse = /** status 200  */ {
  status?: string;
};
export type ApiHealthCheckRetrieveApiArg = void;
export type ApiInvoicesListApiResponse = /** status 200  */ PaginatedInvoiceListRead;
export type ApiInvoicesListApiArg = {
  dueDate?: string;
  /** Filter by due date (from) */
  dueDateFrom?: string;
  /** Filter by due date (to) */
  dueDateTo?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Order type */
  order?: 'asc' | 'desc';
  /** Order by field */
  orderBy?:
    | 'category__name'
    | 'created_at'
    | 'description'
    | 'due_date'
    | 'invoice_date'
    | 'invoice_id'
    | 'is_claimed'
    | 'is_paid'
    | 'paid_date'
    | 'participant'
    | 'service_end_date'
    | 'service_exact_date'
    | 'service_start_date'
    | 'total_amount'
    | 'vendor_name';
  /** Filter by participants (comma-separated) */
  participants?: string;
  /** Search in description, category name, or vendor name */
  search?: string;
  serviceEndDate?: string;
  serviceExactDate?: string;
  serviceStartDate?: string;
};
export type ApiInvoicesDownloadRetrieveApiResponse = /** status 200  */ Blob;
export type ApiInvoicesDownloadRetrieveApiArg = {
  /** The ID of the invoice to download */
  id: number;
};
export type ApiInvoicesRevertCreateApiResponse = /** status 200  */ InvoiceRead;
export type ApiInvoicesRevertCreateApiArg = {
  id: number;
  invoiceRevertFieldRequest: InvoiceRevertFieldRequest;
};
export type ApiInvoicesChangesListApiResponse = /** status 200  */ InvoiceChangeLogRead[];
export type ApiInvoicesChangesListApiArg = {
  invoiceId: number;
};
export type ApiInvoicesEventsListApiResponse = /** status 200  */ InvoiceEventRead[];
export type ApiInvoicesEventsListApiArg = {
  invoiceId: number;
};
export type ApiInvoicesEventsCreateApiResponse = /** status 201  */ InvoiceEventRead;
export type ApiInvoicesEventsCreateApiArg = {
  invoiceId: number;
  invoiceEventRequest: InvoiceEventRequest;
};
export type ApiInvoicesEventsRetrieveApiResponse = /** status 200  */ InvoiceEventRead;
export type ApiInvoicesEventsRetrieveApiArg = {
  id: number;
  invoiceId: number;
};
export type ApiInvoicesEventsPartialUpdateApiResponse = /** status 200  */ InvoiceEventRead;
export type ApiInvoicesEventsPartialUpdateApiArg = {
  id: number;
  invoiceId: number;
  patchedInvoiceEventRequest: PatchedInvoiceEventRequest;
};
export type ApiInvoicesEventsDestroyApiResponse = unknown;
export type ApiInvoicesEventsDestroyApiArg = {
  id: number;
  invoiceId: number;
};
export type ApiInvoicesNotesListApiResponse = /** status 200  */ InvoiceNoteRead[];
export type ApiInvoicesNotesListApiArg = {
  invoiceId: number;
};
export type ApiInvoicesNotesCreateApiResponse = /** status 201  */ InvoiceNoteRead;
export type ApiInvoicesNotesCreateApiArg = {
  invoiceId: number;
  invoiceNoteRequest: InvoiceNoteRequest;
};
export type ApiInvoicesNotesRetrieveApiResponse = /** status 200  */ InvoiceNoteRead;
export type ApiInvoicesNotesRetrieveApiArg = {
  id: number;
  invoiceId: number;
};
export type ApiInvoicesNotesPartialUpdateApiResponse = /** status 200  */ InvoiceNoteRead;
export type ApiInvoicesNotesPartialUpdateApiArg = {
  id: number;
  invoiceId: number;
  patchedInvoiceNoteRequest: PatchedInvoiceNoteRequest;
};
export type ApiInvoicesNotesDestroyApiResponse = unknown;
export type ApiInvoicesNotesDestroyApiArg = {
  id: number;
  invoiceId: number;
};
export type ApiInvoicesRetrieveApiResponse = /** status 200  */ InvoiceRead;
export type ApiInvoicesRetrieveApiArg = {
  id: number;
};
export type ApiInvoicesDestroyApiResponse = unknown;
export type ApiInvoicesDestroyApiArg = {
  id: number;
};
export type ApiInvoicesUpdatePartialUpdateApiResponse = /** status 200  */ InvoiceUpdate;
export type ApiInvoicesUpdatePartialUpdateApiArg = {
  id: number;
  patchedInvoiceUpdateRequest: PatchedInvoiceUpdateRequest;
};
export type ApiInvoicesExportRetrieveApiResponse = /** status 200  */ Blob;
export type ApiInvoicesExportRetrieveApiArg = void;
export type ApiReasonsListApiResponse = /** status 200  */ ReasonRead[];
export type ApiReasonsListApiArg = void;
export type AuthPasswordChangeCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthPasswordChangeCreateApiArg = {
  passwordChangeRequest: PasswordChangeRequest;
};
export type AuthPasswordResetCreateApiResponse = /** status 200  */ PasswordReset;
export type AuthPasswordResetCreateApiArg = {
  passwordResetRequest: PasswordResetRequest;
};
export type AuthPasswordResetConfirmCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthPasswordResetConfirmCreateApiArg = {
  passwordResetConfirmRequest: PasswordResetConfirmRequest;
};
export type AuthSigninCreateApiResponse = /** status 200  */ JwtRead;
export type AuthSigninCreateApiArg = {
  loginRequest: LoginRequest;
};
export type AuthSignoutCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthSignoutCreateApiArg = void;
export type AuthSignupCreateApiResponse = /** status 201  */ CustomRegister;
export type AuthSignupCreateApiArg = {
  customRegisterRequest: CustomRegisterRequestWrite;
};
export type AuthSignupResendEmailCreateApiResponse = /** status 201  */ RestAuthDetailRead;
export type AuthSignupResendEmailCreateApiArg = {
  resendEmailVerificationRequest: ResendEmailVerificationRequest;
};
export type AuthSignupVerifyEmailCreateApiResponse = /** status 200 Email confirmed successfully */ {
  [key: string]: any;
};
export type AuthSignupVerifyEmailCreateApiArg = {
  confirmEmailRequest: ConfirmEmailRequest;
};
export type AuthTokenRefreshCreateApiResponse = /** status 200  */ TokenRefreshRead;
export type AuthTokenRefreshCreateApiArg = {
  tokenRefreshRequest: TokenRefreshRequestWrite;
};
export type AuthUserRetrieveApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserRetrieveApiArg = void;
export type AuthUserUpdateApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserUpdateApiArg = {
  customUserDetailsRequest: CustomUserDetailsRequest;
};
export type AuthUserPartialUpdateApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserPartialUpdateApiArg = {
  patchedCustomUserDetailsRequest: PatchedCustomUserDetailsRequest;
};
export type AuthUserEmailCheckUniqueCreateApiResponse = /** status 200  */ {
  is_available?: boolean;
};
export type AuthUserEmailCheckUniqueCreateApiArg = {
  checkUniqueEmailRequest: CheckUniqueEmailRequest;
};
export type AuthUserParticipantsListApiResponse = /** status 200  */ ParticipantRead[];
export type AuthUserParticipantsListApiArg = void;
export type AuthUserParticipantsCreateApiResponse = /** status 201  */ ParticipantRead;
export type AuthUserParticipantsCreateApiArg = {
  participantRequest: ParticipantRequest;
};
export type AuthUserParticipantsRetrieveApiResponse = /** status 200  */ ParticipantRead;
export type AuthUserParticipantsRetrieveApiArg = {
  id: number;
};
export type AuthUserParticipantsPartialUpdateApiResponse = /** status 200  */ ParticipantRead;
export type AuthUserParticipantsPartialUpdateApiArg = {
  id: number;
  patchedParticipantRequest: PatchedParticipantRequest;
};
export type AuthUserParticipantsDestroyApiResponse = unknown;
export type AuthUserParticipantsDestroyApiArg = {
  id: number;
};
export type EmailsListApiResponse = /** status 200  */ EmailRead[];
export type EmailsListApiArg = {
  /** The email address to filter by */
  email: string;
};
export type EmailsInvoiceCreateApiResponse = /** status 201  */ InvoiceEmailCreate;
export type EmailsInvoiceCreateApiArg = {
  invoiceEmailCreateRequest: InvoiceEmailCreateRequest;
};
export type EmailsInvoiceCheckUniqueCreateApiResponse = /** status 200  */ {
  is_available?: boolean;
};
export type EmailsInvoiceCheckUniqueCreateApiArg = {
  inputRequest: InputRequest;
};
export type EmailsMsSubscriptionRenewRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type EmailsMsSubscriptionRenewRetrieveApiArg = void;
export type ParserUploadCreateApiResponse = /** status 201  */ {
  [key: string]: any;
};
export type ParserUploadCreateApiArg = {
  body: {
    file?: Blob;
  };
};
export type SubscriptionsRetrieveApiResponse = /** status 200  */ SubscriptionRead;
export type SubscriptionsRetrieveApiArg = void;
export type SubscriptionsCancelCreateApiResponse = unknown;
export type SubscriptionsCancelCreateApiArg = void;
export type SubscriptionsCreateCheckoutSessionCreateApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type SubscriptionsCreateCheckoutSessionCreateApiArg = {
  createCheckoutSessionRequest: CreateCheckoutSessionRequest;
};
export type SubscriptionsPortalListApiResponse = /** status 200  */ SubscriptionPortalLink[];
export type SubscriptionsPortalListApiArg = void;
export type SubscriptionsResumeCreateApiResponse = unknown;
export type SubscriptionsResumeCreateApiArg = void;
export type Category = {
  name: string;
};
export type CategoryRead = {
  id: number;
  name: string;
  subcategories: {
    id?: number;
    name?: string;
    subcategories?: CategoryRead[];
  }[];
};
export type InvoiceItem = {
  id: number;
  description: string;
  quantity?: number | null;
  unit_price?: string | null;
  amount?: string | null;
  currency_symbol?: string | null;
  currency_code?: string | null;
  vat?: string | null;
  date?: string | null;
  source?: string | null;
};
export type ClaimedOriginEnum = 'web_extension' | 'manual_entry';
export type BlankEnum = '';
export type NullEnum = null;
export type Invoice = {
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vendor_email: string;
  recipient_email: string;
  vat?: string | null;
  is_manual_upload?: boolean;
  form_submitted_code?: string | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
  original_filename?: string | null;
  participant?: string | null;
};
export type Reason = {
  name: string;
};
export type ReasonRead = {
  id: number;
  name: string;
};
export type InvoiceNote = {
  text: string;
};
export type InvoiceNoteRead = {
  id: number;
  text: string;
  created_at: string;
  updated_at: string;
};
export type InvoiceRead = {
  id: number;
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  updated_at: string;
  created_at: string;
  vendor_email: string;
  recipient_email: string;
  vat?: string | null;
  is_manual_upload?: boolean;
  form_submitted_code?: string | null;
  category: CategoryRead;
  reason: ReasonRead;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
  original_filename?: string | null;
  participant?: string | null;
  notes: InvoiceNoteRead[];
};
export type PaginatedInvoiceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Invoice[];
};
export type PaginatedInvoiceListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRead[];
};
export type InvoiceRevertFieldRequest = {
  /** The name of the field to revert */
  field_name: string;
  /** The ID of the invoice item (if reverting an item field) */
  item_id?: number | null;
};
export type InvoiceChangeLog = {
  field_name: string;
  item_id?: number | null;
  old_value?: any | null;
  new_value?: any | null;
  invoice: number;
  changed_by?: number | null;
};
export type InvoiceChangeLogRead = {
  id: number;
  field_name: string;
  item_id?: number | null;
  old_value?: any | null;
  new_value?: any | null;
  changed_at: string;
  created_at: string;
  updated_at: string;
  invoice: number;
  changed_by?: number | null;
};
export type InvoiceEventTypeEnum =
  | 'invoice_uploaded'
  | 'invoice_emailed'
  | 'claim_submitted'
  | 'payment_due_date'
  | 'invoice_paid'
  | 'ndis_funds_received'
  | 'custom_event';
export type InvoiceEvent = {
  type: InvoiceEventTypeEnum;
  date?: string | null;
  name?: string | null;
  details?: string | null;
  is_manual: boolean;
};
export type InvoiceEventRead = {
  id: number;
  type: InvoiceEventTypeEnum;
  date?: string | null;
  name?: string | null;
  details?: string | null;
  is_manual: boolean;
};
export type InvoiceEventRequest = {
  type: InvoiceEventTypeEnum;
  date?: string | null;
  name?: string | null;
  details?: string | null;
  is_manual: boolean;
};
export type PatchedInvoiceEventRequest = {
  type?: InvoiceEventTypeEnum;
  date?: string | null;
  name?: string | null;
  details?: string | null;
  is_manual?: boolean;
};
export type InvoiceNoteRequest = {
  text: string;
};
export type PatchedInvoiceNoteRequest = {
  text?: string;
};
export type InvoiceUpdate = {
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vat?: string | null;
  form_submitted_code?: string | null;
  category_id?: number | null;
  reason_id?: number | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
  original_filename?: string | null;
  participant?: string | null;
};
export type InvoiceItemRequest = {
  id: number;
  description: string;
  quantity?: number | null;
  unit_price?: string | null;
  amount?: string | null;
  currency_symbol?: string | null;
  currency_code?: string | null;
  vat?: string | null;
  date?: string | null;
  source?: string | null;
};
export type PatchedInvoiceUpdateRequest = {
  invoice_id?: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items?: InvoiceItemRequest[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vat?: string | null;
  form_submitted_code?: string | null;
  category_id?: number | null;
  reason_id?: number | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
  original_filename?: string | null;
  participant?: string | null;
};
export type RestAuthDetail = {};
export type RestAuthDetailRead = {
  detail: string;
};
export type PasswordChangeRequest = {
  new_password1: string;
  new_password2: string;
};
export type PasswordReset = {
  /** User's email address */
  email: string;
  /** Frontend URL for password reset */
  frontend_url: string;
};
export type PasswordResetRequest = {
  /** User's email address */
  email: string;
  /** Frontend URL for password reset */
  frontend_url: string;
};
export type PasswordResetConfirmRequest = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};
export type CustomUserDetails = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
  is_events_notification_enabled?: boolean;
};
export type ParticipantTypeEnum = 'nominee' | 'child_representative' | 'nominee_child_representative' | 'user';
export type Participant = {
  first_name: string;
  last_name: string;
  type: ParticipantTypeEnum;
  is_active?: boolean;
};
export type ParticipantRead = {
  id: number;
  first_name: string;
  last_name: string;
  type: ParticipantTypeEnum;
  is_active?: boolean;
  created_at: string;
  updated_at: string;
  user: number;
};
export type CustomUserDetailsRead = {
  pk: number;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
  is_events_notification_enabled?: boolean;
  participants: ParticipantRead[];
};
export type Jwt = {
  access: string;
  refresh: string;
  user: CustomUserDetails;
};
export type JwtRead = {
  access: string;
  refresh: string;
  user: CustomUserDetailsRead;
};
export type LoginRequest = {
  username?: string;
  email?: string;
  password: string;
};
export type CustomRegister = {
  username?: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type CustomRegisterRequest = {
  username?: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type CustomRegisterRequestWrite = {
  username?: string;
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type ResendEmailVerificationRequest = {
  email: string;
};
export type ConfirmEmailRequest = {
  /** The confirmation key sent via email. */
  key: string;
  /** The email address being confirmed. */
  email: string;
};
export type TokenRefresh = {};
export type TokenRefreshRead = {
  access: string;
};
export type TokenRefreshRequest = {};
export type TokenRefreshRequestWrite = {
  refresh: string;
};
export type CustomUserDetailsRequest = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
  is_events_notification_enabled?: boolean;
};
export type PatchedCustomUserDetailsRequest = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
  is_events_notification_enabled?: boolean;
};
export type CheckUniqueEmailRequest = {
  email: string;
};
export type ParticipantRequest = {
  first_name: string;
  last_name: string;
  type: ParticipantTypeEnum;
  is_active?: boolean;
};
export type PatchedParticipantRequest = {
  first_name?: string;
  last_name?: string;
  type?: ParticipantTypeEnum;
  is_active?: boolean;
};
export type Email = {
  sender: string;
  recipient: string;
  recipients: any;
  message_id: string;
  has_attachments: boolean;
  subject?: string | null;
  body?: string | null;
  cc_recipients?: any | null;
  bcc_recipients?: any | null;
  message_created?: string | null;
  sent_datetime?: string | null;
  reply_to?: any | null;
  message_web_link?: string | null;
};
export type EmailAttachment = {
  content_type: string;
  is_inline: boolean;
  last_modified_datetime: string;
  name: string;
  size: number;
  storage_file_path: string;
  storage_file_id: string;
  content_location?: string | null;
  content_id?: string | null;
  email: number;
};
export type EmailAttachmentRead = {
  id: number;
  content_type: string;
  is_inline: boolean;
  last_modified_datetime: string;
  name: string;
  size: number;
  storage_file_path: string;
  storage_file_id: string;
  content_location?: string | null;
  content_id?: string | null;
  created_at: string;
  updated_at: string;
  email: number;
};
export type EmailRead = {
  id: number;
  attachments: EmailAttachmentRead[];
  sender: string;
  recipient: string;
  recipients: any;
  message_id: string;
  has_attachments: boolean;
  subject?: string | null;
  body?: string | null;
  cc_recipients?: any | null;
  bcc_recipients?: any | null;
  message_created?: string | null;
  sent_datetime?: string | null;
  reply_to?: any | null;
  message_web_link?: string | null;
  created_at: string;
  updated_at: string;
};
export type InvoiceEmailCreate = {
  email: string;
};
export type InvoiceEmailCreateRequest = {
  email: string;
};
export type InputRequest = {
  email: string;
};
export type Subscription = {};
export type SubscriptionTypeEnum = 'annual' | 'monthly';
export type StatusEnum = 'active' | 'inactive' | 'canceled';
export type SubscriptionRead = {
  subscription_type: SubscriptionTypeEnum | null;
  is_trial: boolean;
  trial_days_left: number;
  days_left: number;
  status: StatusEnum;
};
export type CreateCheckoutSessionRequest = {
  subscription_type: SubscriptionTypeEnum;
  success_url: string;
  cancel_url: string;
};
export type SubscriptionPortalLink = {
  url: string;
};
export const {
  useApiCategoriesListQuery,
  useApiHealthCheckRetrieveQuery,
  useApiInvoicesListQuery,
  useApiInvoicesDownloadRetrieveQuery,
  useApiInvoicesRevertCreateMutation,
  useApiInvoicesChangesListQuery,
  useApiInvoicesEventsListQuery,
  useApiInvoicesEventsCreateMutation,
  useApiInvoicesEventsRetrieveQuery,
  useApiInvoicesEventsPartialUpdateMutation,
  useApiInvoicesEventsDestroyMutation,
  useApiInvoicesNotesListQuery,
  useApiInvoicesNotesCreateMutation,
  useApiInvoicesNotesRetrieveQuery,
  useApiInvoicesNotesPartialUpdateMutation,
  useApiInvoicesNotesDestroyMutation,
  useApiInvoicesRetrieveQuery,
  useApiInvoicesDestroyMutation,
  useApiInvoicesUpdatePartialUpdateMutation,
  useApiInvoicesExportRetrieveQuery,
  useApiReasonsListQuery,
  useAuthPasswordChangeCreateMutation,
  useAuthPasswordResetCreateMutation,
  useAuthPasswordResetConfirmCreateMutation,
  useAuthSigninCreateMutation,
  useAuthSignoutCreateMutation,
  useAuthSignupCreateMutation,
  useAuthSignupResendEmailCreateMutation,
  useAuthSignupVerifyEmailCreateMutation,
  useAuthTokenRefreshCreateMutation,
  useAuthUserRetrieveQuery,
  useAuthUserUpdateMutation,
  useAuthUserPartialUpdateMutation,
  useAuthUserEmailCheckUniqueCreateMutation,
  useAuthUserParticipantsListQuery,
  useAuthUserParticipantsCreateMutation,
  useAuthUserParticipantsRetrieveQuery,
  useAuthUserParticipantsPartialUpdateMutation,
  useAuthUserParticipantsDestroyMutation,
  useEmailsListQuery,
  useEmailsInvoiceCreateMutation,
  useEmailsInvoiceCheckUniqueCreateMutation,
  useEmailsMsSubscriptionRenewRetrieveQuery,
  useParserUploadCreateMutation,
  useSubscriptionsRetrieveQuery,
  useSubscriptionsCancelCreateMutation,
  useSubscriptionsCreateCheckoutSessionCreateMutation,
  useSubscriptionsPortalListQuery,
  useSubscriptionsResumeCreateMutation,
} = injectedRtkApi;
