import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledRegularTextField } from '@pages/styled';

import { Box, Typography } from '@mui/material';

const Participant: FC<{ index: number; id: string; title: string; disabled: boolean }> = ({
  index,
  title,
  disabled,
  id = 0,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { downSm } = useBreakpoints();

  return (
    <Box>
      <Typography mb={2}>{t(title)}</Typography>
      <Box display="flex" gap={1} flexDirection={downSm ? 'column' : 'row'}>
        <Controller
          control={control}
          name={`data.${index}.first_name`}
          render={({ field }) => (
            <StyledRegularTextField
              fullWidth
              label={t('common.inputs.firstName')}
              type="text"
              variant="outlined"
              data-testid={`form-field-${id}-participant-first-name`}
              inputProps={{ maxLength: 100 }}
              disabled={disabled}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={`data.${index}.last_name`}
          render={({ field }) => (
            <StyledRegularTextField
              fullWidth
              label={t('common.inputs.lastName')}
              type="text"
              variant="outlined"
              data-testid={`form-field-${id}-participant-last-name`}
              inputProps={{ maxLength: 100 }}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default memo(Participant);
