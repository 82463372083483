import { FC, Fragment, memo } from 'react';
import { useUser } from '@hooks/api/useUser';
import { useBreakpoints } from '@hooks/useBreakpoints';
import Detail from '@pages/InvoiceDetails/components/DetailsTab/components/Detail';
import { useInvoiceDetailsConfig } from '@pages/InvoiceDetails/components/DetailsTab/components/hooks/useInvoiceDetailsConfig';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { formatABN } from '@pages/InvoiceDetails/utils';

import { Grid2 } from '@mui/material';

const InvoiceDetailsSection: FC = () => {
  const { data: userData } = useUser();
  const { invoiceData, changesData } = useInvoiceDetailsNewContext();

  const { config: detailsConfig } = useInvoiceDetailsConfig(userData, invoiceData, changesData);
  const { downSm } = useBreakpoints();

  return (
    <Grid2 container rowSpacing={3} columnSpacing={2} sx={{ flexGrow: 1 }} width="100%">
      {detailsConfig.map(({ id, title, value, iconStatus, renderCondition, dimensions, tooltipTitle }) => {
        const isServiceExactDateField = id === 3; // SERVICE_EXACT_DATE_FIELD
        const isAbnField = id === 9; // ABN_FIELD

        const shouldBreakAfter = isServiceExactDateField || isAbnField;

        return (
          <Fragment key={id}>
            {renderCondition && (
              <Grid2 size={dimensions} gap={3}>
                <Detail
                  title={title}
                  value={isAbnField ? formatABN(value as string) : value}
                  iconStatus={iconStatus}
                  tooltipTitle={tooltipTitle}
                />
              </Grid2>
            )}

            {!downSm && shouldBreakAfter && (
              <Grid2
                size={{ xs: 0, sm: 0, md: 9, lg: 0 }}
                sx={{ display: isServiceExactDateField && renderCondition ? 'flex' : 'none' }}
              />
            )}
          </Fragment>
        );
      })}
    </Grid2>
  );
};

export default memo(InvoiceDetailsSection);
