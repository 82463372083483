import { useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthPasswordChangeCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePasswordValidation } from '@hooks/usePasswordValidation';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { changePasswordSchema } from '@pages/Settings/schema';
import { StyledIconButton, StyledModal, StyledTextField } from '@pages/Settings/styled';
import { ChangePasswordSchema } from '@pages/Settings/types';
import { getErrorKeys, getErrorText } from '@utils/getError';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';
import LoadingButton from '@components/LoadingButton';
import PasswordValidationList from '@components/PasswordValidationList';

import { ErrorObj } from '@/shared/types';

const ChangePasswordModal = NiceModal.create(() => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();
  const modal = useModal();
  const snackbar = useSnackbar();
  const { downSm } = useBreakpoints();
  const { passwordErrors, validatePassword } = usePasswordValidation();

  const [changePasswordMutation, { isLoading: isLoadingChangePassword }] = useAuthPasswordChangeCreateMutation();

  const form = useForm<ChangePasswordSchema>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: '',
      new_password1: '',
      new_password2: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const handleConfirmClick: SubmitHandler<ChangePasswordSchema> = async ({ new_password1, new_password2 }) => {
    try {
      await changePasswordMutation({
        passwordChangeRequest: {
          new_password1,
          new_password2,
        },
      });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, getErrorText(getErrorKeys(err as ErrorObj))), { variant: ERROR });
    } finally {
      modal.resolve(true);
      modal.remove();
    }
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <Box padding="20px 30px">
        <Box textAlign="end" pb={2}>
          <StyledIconButton disableRipple onClick={handleCancelClick}>
            <CloseIcon />
          </StyledIconButton>
        </Box>
        <Typography fontSize={20} fontFamily="WFVisualSans">
          {t('settings.modal.changePassword.title')}
        </Typography>

        <Box mt={4}>
          <FormProvider {...form}>
            <form autoComplete="off" onSubmit={form.handleSubmit(handleConfirmClick, err => console.log(err))}>
              <Box width="100%">
                <Box mb={4}>
                  <Controller
                    name="currentPassword"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <StyledTextField
                        variant="outlined"
                        type="password"
                        error={Boolean(fieldState.error)}
                        label={t('settings.modal.changePassword.currentPassword')}
                        helperText={
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                        }
                        {...field}
                      />
                    )}
                  />
                </Box>

                <Divider />

                <Box mt={4}>
                  <Controller
                    name="new_password1"
                    control={form.control}
                    render={({ field: { onChange, ...field }, fieldState }) => (
                      <InputPassword
                        fullWidth
                        variant="outlined"
                        label={t('settings.modal.changePassword.newPassword')}
                        autoComplete="off"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                        }
                        onChange={e => {
                          validatePassword(e.target.value);
                          onChange(e);
                          form.trigger('new_password2');

                          if (!isTouched) {
                            setIsTouched(true);
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </Box>

                <Box mt={4}>
                  <Controller
                    name="new_password2"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <InputPassword
                        fullWidth
                        variant="outlined"
                        label={t('settings.modal.changePassword.repeatPassword')}
                        autoComplete="off"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                        }
                        {...field}
                      />
                    )}
                  />
                </Box>

                <Box mt={3}>
                  <PasswordValidationList errors={passwordErrors} isTouched={isTouched} />
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center" mt="44px">
                <StyledTextButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handleCancelClick}
                  variant="text"
                >
                  <Typography fontSize={14}>{t('common.cancel')}</Typography>
                </StyledTextButton>
                <LoadingButton
                  fullWidth
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  variant="contained"
                  loading={isLoadingChangePassword}
                  color="primary"
                  type="submit"
                  sx={{ maxHeight: '42px', boxShadow: 2, width: '111px' }}
                  disabled={!!Object.keys(form.formState.errors).length}
                >
                  <Typography>{t('common.save')}</Typography>
                </LoadingButton>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const ChangePasswordModalId = 'ChangePasswordModal';

NiceModal.register(ChangePasswordModalId, ChangePasswordModal);
