import { FC, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedInvoiceListRead } from '@api/api';
import { ROUTING } from '@constants/routing';
import NoInvoicesMessage from '@pages/Invoices/components/TableView/NoInvoicesMessage';
import { useListColumns } from '@pages/Invoices/hooks/useListColumns';
import { StyledDataGrid } from '@pages/Invoices/styled';

import { LinearProgress } from '@mui/material';
import { GridEventListener, GridPaginationModel } from '@mui/x-data-grid';

export interface Page {
  pageSize: number;
  page: number;
}

export interface InvoicesTableProps {
  invoices?: PaginatedInvoiceListRead;
  isLoading: boolean;
  handlePageChange: (newPaginationModel: GridPaginationModel) => void;
  paginationModel: Page;
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices, isLoading, handlePageChange, paginationModel }) => {
  const invoicesData = useMemo(() => invoices?.results || [], [invoices?.results]);
  const columns = useListColumns();
  const navigate = useNavigate();

  const props = useMemo(
    () => ({
      rowCount: invoices?.count,
      rows: invoicesData?.map(v => ({
        ...v,
      })),
      columns,
    }),
    [invoices?.count, invoicesData, columns],
  );

  const handleRowClick: GridEventListener<'rowClick'> = ({ row: { id } }) => {
    if (id) {
      navigate(`/${ROUTING.INVOICES}/${id}`);
    }
  };

  return (
    <StyledDataGrid
      paginationMode="server"
      pagination={invoicesData.length > 0 ? true : undefined}
      hideFooter={invoicesData.length === 0}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      rowSelection={false}
      rowHeight={72}
      loading={isLoading}
      showCellVerticalBorder={false}
      pageSizeOptions={[10, 25, 50]}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoInvoicesMessage,
        ...(!invoices?.count && { columnHeaders: () => null }),
      }}
      onPaginationModelChange={handlePageChange}
      paginationModel={paginationModel}
      onRowClick={handleRowClick}
      initialState={{
        sorting: {
          sortModel: [{ field: 'due_date', sort: 'desc' }],
        },
      }}
      {...props}
    />
  );
};

export default memo(InvoicesTable);
