import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    participant: yup.boolean().nullable(),
    nomineeOrChildRepresentative: yup.boolean().nullable(),
    refuse: yup.boolean().nullable(),
    data: yup.array().of(
      yup.object().shape({
        first_name: yup.string().nullable(),
        last_name: yup.string().nullable(),
      }),
    ),
  })
  .test(
    'at-least-one-checkbox',
    'At least one option must be selected',
    // @ts-ignore
    values => values.participant || values.nomineeOrChildRepresentative,
  );
