import { FC, memo } from 'react';
import { useTabs } from '@hooks/useTabs';
import DetailsTab from '@pages/InvoiceDetails/components/DetailsTab';
import NotesTab from '@pages/InvoiceDetails/components/NotesTab';
import TasksTab from '@pages/InvoiceDetails/components/TasksTab';

import { Box } from '@mui/material';

import { Indicators } from '../enums';

const ActiveTab: FC = () => {
  const { tab } = useTabs(Indicators.DETAILS, Indicators);

  return (
    <>
      <Box display="flex" flexDirection="column" mt={1}>
        {tab === Indicators.DETAILS ? <DetailsTab /> : null}
        {tab === Indicators.TASKS ? <TasksTab /> : null}
        {tab === Indicators.NOTES ? <NotesTab /> : null}
      </Box>
    </>
  );
};

export default memo(ActiveTab);
