import { FC, memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BREAKPOINTS, DOWN, MAX_DESCRIPTION_CHARACTERS, MAX_FORMATTED_ABN_SYMBOLS, UP } from '@constants/common';
import { useUser } from '@hooks/api/useUser';
import { useResponsive } from '@hooks/useResponsive';
import {
  StyledCancelButton,
  StyledCheckboxFormControlLabel,
  StyledEditInvoiceAutocomplete,
  StyledEditInvoiceTextField,
  StyledFormControl,
  StyledInputLabel,
  StyledSelect,
} from '@pages/InvoiceDetails/components/DetailsTab/styled';
import {
  ABN_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  HAS_ABN_FIELD,
  MISSING_REASON_FIELD,
  MORE_THAN_ONE_DAY_FIELD,
  ORIGINAL_FILE_NAME,
  PARTICIPANT_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_EXACT_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  TOTAL_AMOUNT_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { formatABN, getCategoriesOptions, getReasonsOptions } from '@pages/InvoiceDetails/utils';
import { formatCurrency } from '@utils/formatCurrency';

import EventIcon from '@mui/icons-material/Event';
import { Box, Checkbox, FormHelperText, InputAdornment, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import AutocompletePaper from '@components/AutocompletePaper';
import LoadingButton from '@components/LoadingButton';

const EditInvoiceDetailsForm: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const [isFocusedDescField, setIsFocusedDescField] = useState(false);
  const [isFocusedProviderNameField, setIsFocusedProviderNameField] = useState(false);
  const { control, watch, setError, trigger, clearErrors, setValue, formState } = useFormContext();
  const { categoriesOptions, invoiceData, reasonOptions, toggleEdit, editInvoice, setEditInvoice, handleResetForm } =
    useInvoiceDetailsNewContext();
  const { data: userData } = useUser();

  const theme = useTheme();

  const moreThanOneDay = watch(MORE_THAN_ONE_DAY_FIELD);
  const showAbnField = watch(HAS_ABN_FIELD);
  const descField = watch(DESCRIPTION_FIELD);
  const providerField = watch(VENDOR_NAME_FIELD);
  const supportExactDate = watch(SERVICE_EXACT_DATE_FIELD);
  const supportStartDate = watch(SERVICE_START_DATE_FIELD);
  const supportEndDate = watch(SERVICE_END_DATE_FIELD);

  const handleCancel = () => {
    toggleEdit(editInvoice, setEditInvoice);
    handleResetForm();
  };

  const downMd = useResponsive(DOWN, BREAKPOINTS.MD);
  const downSm = useResponsive(DOWN, BREAKPOINTS.SM);
  const downLg = useResponsive(DOWN, BREAKPOINTS.LG);
  const upSm = useResponsive(UP, BREAKPOINTS.SM);

  const getTopGridColumnsSize = () => {
    if (downSm) {
      return '1fr';
    }
    if (downMd) {
      return 'repeat(2, 1fr)';
    }
    if (downLg) {
      return 'repeat(3, 1fr)';
    }

    return 'repeat(4, 1fr)';
  };

  const getBottomGridColumnsSize = () => {
    if (!downMd) {
      return 'repeat(2, 1fr)';
    }
    return '1fr';
  };

  const formHasErrors = !!Object.keys(formState.errors).length;

  useEffect(() => {
    if (descField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(DESCRIPTION_FIELD, {
        type: 'manual',
        message: `${descField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(DESCRIPTION_FIELD);
    }
    if (providerField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(VENDOR_NAME_FIELD, {
        type: 'manual',
        message: `${providerField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(VENDOR_NAME_FIELD);
    }
    if (moreThanOneDay) {
      trigger(SERVICE_START_DATE_FIELD);
      trigger(SERVICE_END_DATE_FIELD);
    }
    if (showAbnField) {
      trigger(HAS_ABN_FIELD);
      trigger(ABN_FIELD);
      clearErrors(MISSING_REASON_FIELD);
      trigger(MISSING_REASON_FIELD);
    }
    if (!showAbnField) {
      setValue(ABN_FIELD, '');
      clearErrors(ABN_FIELD);
      trigger(MISSING_REASON_FIELD);
    }
  }, [clearErrors, descField, moreThanOneDay, providerField.length, setError, setValue, showAbnField, trigger]);

  return (
    <>
      <Box display="grid" gridTemplateColumns={getTopGridColumnsSize} gap={3}>
        <Controller
          control={control}
          name={PARTICIPANT_FIELD}
          render={({ field, fieldState }) => (
            <Box>
              <StyledFormControl fullWidth disabled={userData?.participants.length === 1}>
                <StyledInputLabel id={`${PARTICIPANT_FIELD}-select-label`} error={Boolean(fieldState.error)}>
                  {t('dashboard.updateDetails.participantTitle')}
                </StyledInputLabel>
                <StyledSelect
                  labelId={`${PARTICIPANT_FIELD}-select-label`}
                  id={`${PARTICIPANT_FIELD}-select`}
                  label={t('dashboard.updateDetails.participantTitle')}
                  error={Boolean(fieldState.error)}
                  {...field}
                  onBlur={() => {
                    trigger(PARTICIPANT_FIELD);
                  }}
                >
                  {userData?.participants?.map(({ id, first_name, last_name }) => (
                    <MenuItem key={id} value={`${first_name} ${last_name}`}>
                      {`${first_name} ${last_name}`}
                    </MenuItem>
                  ))}
                </StyledSelect>
                {fieldState.error && (
                  <Typography color="error" mt="3px" mx="14px" fontSize={12} fontWeight={400}>
                    {fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })}
                  </Typography>
                )}
              </StyledFormControl>
            </Box>
          )}
        />

        <Box display="flex" id={TOTAL_AMOUNT_FIELD}>
          <Controller
            control={control}
            name={TOTAL_AMOUNT_FIELD}
            render={({ field, fieldState }) => (
              <StyledEditInvoiceTextField
                fullWidth
                variant="outlined"
                label={t('dashboard.updateDetails.totalAmountTitle')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'total amount input field',
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                InputProps={{
                  startAdornment: field.value && (
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      $
                    </InputAdornment>
                  ),
                }}
                {...field}
                onChange={e => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal
                  field.onChange(rawValue);
                }}
                onBlur={() => {
                  if (field.value) {
                    field.onChange(formatCurrency(field.value));
                  }
                }}
                onFocus={() => {
                  if (field.value) {
                    const rawValue = field.value.replace(/[^0-9.]/g, '');
                    field.onChange(rawValue);
                  }
                }}
              />
            )}
          />
        </Box>

        {/* PLACEHOLDER FOR DESIGN PURPOSE */}
        {downLg && !downMd && <Box width="100%" />}

        {/* DATE SECTION WRAPPER */}
        <Box display="flex" flexDirection="column" gap={1} sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
          {!moreThanOneDay && (
            <Controller
              control={control}
              name={SERVICE_EXACT_DATE_FIELD}
              render={({ field, fieldState }) => (
                <Box id={SERVICE_EXACT_DATE_FIELD}>
                  <DatePicker
                    {...field}
                    label={t('dashboard.updateDetails.serviceDateField')}
                    slots={{
                      openPickerIcon: EventIcon,
                    }}
                    slotProps={{
                      textField: () => ({
                        sx: {
                          svg: { color: theme.colors.primary.main },
                          fontWeight: 400,
                          '& .MuiInputBase-root': {
                            fontWeight: 400,
                            color: fieldState.error ? theme.colors.error.main : theme.colors.black,
                          },
                          '& .MuiFormLabel-root': {
                            fontWeight: 400,
                            fontSize: '16px',
                          },
                        },
                        variant: 'outlined',
                        error: Boolean(fieldState.error),
                        helperText:
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                      }),
                    }}
                  />
                </Box>
              )}
            />
          )}

          {moreThanOneDay && (
            <>
              <Box display="flex" gap={3} alignItems="center" sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
                <Controller
                  control={control}
                  name={SERVICE_START_DATE_FIELD}
                  render={({ field, fieldState }) => (
                    <Box id={SERVICE_START_DATE_FIELD} width="100%">
                      <DatePicker
                        {...field}
                        label={t('dashboard.updateDetails.serviceStartDateField')}
                        slots={{
                          openPickerIcon: EventIcon,
                        }}
                        slotProps={{
                          textField: () => ({
                            sx: {
                              svg: { color: theme.colors.primary.main },
                              fontWeight: 400,
                              flexGrow: 1,
                              '& .MuiInputBase-root': {
                                fontWeight: 400,
                                color: fieldState.error ? theme.colors.error.main : theme.colors.black,
                              },
                              '& .MuiFormLabel-root': {
                                fontWeight: 400,
                                fontSize: '16px',
                              },
                            },
                            variant: 'outlined',
                            error: Boolean(fieldState.error),
                            helperText:
                              fieldState.error &&
                              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                          }),
                        }}
                        maxDate={supportEndDate}
                      />
                    </Box>
                  )}
                />
              </Box>
            </>
          )}

          {/* CHECKBOX */}
          <StyledCheckboxFormControlLabel
            control={
              <Controller
                control={control}
                name={MORE_THAN_ONE_DAY_FIELD}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => {
                      if (e.target.checked && supportExactDate) {
                        setValue(SERVICE_START_DATE_FIELD, supportExactDate);
                      }
                      if (!e.target.checked && supportStartDate) {
                        setValue(SERVICE_EXACT_DATE_FIELD, supportStartDate);
                      }
                      field.onChange(e.target.checked);
                      trigger();
                    }}
                  />
                )}
              />
            }
            labelPlacement="end"
            label={t('dashboard.updateDetails.moreThanOneDayLabel')}
          />
        </Box>

        {moreThanOneDay && (
          <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
            <Controller
              control={control}
              name={SERVICE_END_DATE_FIELD}
              render={({ field, fieldState }) => (
                <Box id={SERVICE_END_DATE_FIELD} width="100%">
                  <DatePicker
                    {...field}
                    label={t('dashboard.updateDetails.serviceEndDateField')}
                    slots={{
                      openPickerIcon: EventIcon,
                    }}
                    slotProps={{
                      textField: () => ({
                        sx: {
                          svg: { color: theme.colors.primary.main },
                          fontWeight: 400,
                          flexGrow: 1,
                          '& .MuiInputBase-root': {
                            fontWeight: 400,
                            color: fieldState.error ? theme.colors.error.main : theme.colors.black,
                          },
                          '& .MuiFormLabel-root': {
                            fontWeight: 400,
                            fontSize: '16px',
                          },
                        },
                        variant: 'outlined',
                        error: Boolean(fieldState.error),
                        helperText:
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                      }),
                    }}
                    minDate={supportStartDate}
                  />
                </Box>
              )}
            />
          </Box>
        )}
      </Box>

      <Box display="grid" gap={3} gridTemplateColumns={getBottomGridColumnsSize} mt={4}>
        <Controller
          control={control}
          name={DESCRIPTION_FIELD}
          render={({ field, fieldState }) => {
            const charsOverLimit = field.value ? Math.max(0, field.value.length - MAX_DESCRIPTION_CHARACTERS) : 0;

            const isMaxLengthError = field.value?.length > MAX_DESCRIPTION_CHARACTERS;

            return (
              <Box id={DESCRIPTION_FIELD}>
                <StyledEditInvoiceTextField
                  fullWidth
                  variant="outlined"
                  label={t('dashboard.updateDetails.descriptionTitle')}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'description input field',
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error ? (
                      isFocusedDescField && isMaxLengthError ? (
                        `${charsOverLimit} characters over maximum`
                      ) : (
                        t(fieldState.error.message as string)
                      )
                    ) : (
                      <Box display="flex" justifyContent="end">
                        <Typography variant="caption">
                          {`${field?.value?.length || 0}/${MAX_DESCRIPTION_CHARACTERS}`}
                        </Typography>
                      </Box>
                    )
                  }
                  {...field}
                  onFocus={() => setIsFocusedDescField(true)}
                  onBlur={() => {
                    setIsFocusedDescField(false);
                    field.onBlur();
                    trigger(DESCRIPTION_FIELD);
                  }}
                />
              </Box>
            );
          }}
        />

        <Controller
          name={CATEGORY_FIELD}
          control={control}
          render={({ field, fieldState }) => (
            <Box id={CATEGORY_FIELD}>
              <StyledEditInvoiceAutocomplete
                {...field}
                options={getCategoriesOptions(categoriesOptions)}
                disablePortal
                disableClearable
                PaperComponent={AutocompletePaper}
                renderInput={params => (
                  <StyledEditInvoiceTextField
                    error={Boolean(fieldState.error)}
                    variant="outlined"
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                    label={t('dashboard.invoiceDetails.selectSupportCategory')}
                    {...params}
                  />
                )}
                onChange={(_, d) => field.onChange(d)}
              />
            </Box>
          )}
        />

        <Controller
          control={control}
          name={VENDOR_NAME_FIELD}
          render={({ field, fieldState }) => {
            const charsOverLimit = field.value ? Math.max(0, field.value.length - MAX_DESCRIPTION_CHARACTERS) : 0;
            const isMaxLengthError = field.value?.length > MAX_DESCRIPTION_CHARACTERS;

            return (
              <Box id={VENDOR_NAME_FIELD}>
                <StyledEditInvoiceTextField
                  fullWidth
                  variant="outlined"
                  label={t('dashboard.updateDetails.providerTitle')}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'provider input field',
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error ? (
                      isFocusedProviderNameField && isMaxLengthError ? (
                        `${charsOverLimit} characters over maximum`
                      ) : (
                        t(fieldState.error.message as string)
                      )
                    ) : (
                      <>
                        {isFocusedProviderNameField && (
                          <Box display="flex" justifyContent="end">
                            <Typography variant="caption">
                              {`${field?.value?.length || 0}/${MAX_DESCRIPTION_CHARACTERS}`}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )
                  }
                  {...field}
                  onFocus={() => setIsFocusedProviderNameField(true)}
                  onBlur={() => {
                    setIsFocusedProviderNameField(false);
                    trigger(VENDOR_NAME_FIELD);
                  }}
                />
              </Box>
            );
          }}
        />

        <Box id={ABN_FIELD}>
          <Controller
            control={control}
            name={ABN_FIELD}
            render={({ field, fieldState }) => (
              <Box width="270px">
                <StyledEditInvoiceTextField
                  fullWidth
                  disabled={!showAbnField}
                  variant="outlined"
                  label={!field.value && !showAbnField ? 'None' : t('dashboard.updateDetails.providerAbnTitle')}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'provider abn input field',
                    maxLength: MAX_FORMATTED_ABN_SYMBOLS,
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                  value={formatABN(field.value)}
                  onChange={e => {
                    field.onChange(e.target.value.replace(/\D/g, ''));
                  }}
                />
              </Box>
            )}
          />
          <StyledCheckboxFormControlLabel
            control={
              <Controller
                control={control}
                name={HAS_ABN_FIELD}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => {
                      field.onChange(e.target.checked);
                      trigger(ABN_FIELD);
                    }}
                  />
                )}
              />
            }
            labelPlacement="end"
            label={t('dashboard.updateDetails.hasAbnLabel')}
          />
        </Box>

        {/* ToDo update this field only if it has value different from t('common.none') or empty string */}
        <Controller
          control={control}
          name={ORIGINAL_FILE_NAME}
          render={({ field, fieldState }) => (
            <Box id={ORIGINAL_FILE_NAME}>
              <StyledEditInvoiceTextField
                fullWidth
                error={Boolean(fieldState.error)}
                disabled={!invoiceData?.original_filename}
                variant="outlined"
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                label={t('dashboard.invoiceDetails.attachmentName')}
                {...field}
                value={invoiceData?.original_filename ? field?.value : t('common.none')}
              />
              {invoiceData?.original_filename && (
                <FormHelperText style={{ marginLeft: '15px' }}>
                  {t('dashboard.updateDetails.filenameHelpText')}
                </FormHelperText>
              )}
            </Box>
          )}
        />

        {!showAbnField && (
          <Controller
            name={MISSING_REASON_FIELD}
            control={control}
            render={({ field, fieldState }) => (
              <Box id={MISSING_REASON_FIELD}>
                <StyledEditInvoiceAutocomplete
                  {...field}
                  options={getReasonsOptions(reasonOptions)}
                  disablePortal
                  disableClearable
                  PaperComponent={AutocompletePaper}
                  renderInput={params => (
                    <StyledEditInvoiceTextField
                      error={Boolean(fieldState.error)}
                      variant="outlined"
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      label={t('dashboard.abnSelectReason')}
                      {...params}
                    />
                  )}
                  onChange={(_, d) => field.onChange(d)}
                />
              </Box>
            )}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={upSm ? 'row' : 'column-reverse'}
        justifyContent={upSm ? 'end' : 'center'}
        alignItems="baseline"
        gap={upSm ? 3 : '36px'}
        mt={6}
      >
        <StyledCancelButton fullWidth={!upSm} variant="text" color="primary" onClick={handleCancel} disableElevation>
          {t('common.cancel')}
        </StyledCancelButton>
        <LoadingButton
          fullWidth={!upSm}
          loading={loading}
          disabled={formHasErrors}
          type="submit"
          color="primary"
          variant="contained"
          style={{ padding: '6px 16px', width: upSm ? '150px' : '100%' }}
          sx={{ boxShadow: 2 }}
        >
          {t('common.save')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default memo(EditInvoiceDetailsForm);
