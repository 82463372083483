import { FC, Fragment, memo, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditField from '@pages/Settings/components/AccountTab/components/EditField';
import UserDetail from '@pages/Settings/components/AccountTab/components/UserDetail';
import { EMAIL_FIELD, FIRSTNAME_FIELD, LASTNAME_FIELD } from '@pages/Settings/constants';
import { toggleEdit } from '@store/settingsSlice';
import { RootState } from '@store/store';

import { Box, Divider, Typography } from '@mui/material';

const PersonalDetails: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const isEditFirstName = useSelector((state: RootState) => state.settings.isEditFirstName);
  const isEditLastName = useSelector((state: RootState) => state.settings.isEditLastName);
  const isEditEmail = useSelector((state: RootState) => state.settings.isEditEmail);

  const [firstNameVal, lastNameVal, emailVal] = useWatch({
    control,
    name: [FIRSTNAME_FIELD, LASTNAME_FIELD, EMAIL_FIELD],
  });

  const detailsConfig = useMemo(
    () => [
      {
        title: 'settings.firstName',
        value: firstNameVal,
        toggleEdit: () => dispatch(toggleEdit(FIRSTNAME_FIELD)),
        isEdit: isEditFirstName,
        fieldName: FIRSTNAME_FIELD,
      },
      {
        title: 'settings.lastName',
        value: lastNameVal,
        toggleEdit: () => dispatch(toggleEdit(LASTNAME_FIELD)),
        isEdit: isEditLastName,
        fieldName: LASTNAME_FIELD,
      },
      {
        title: 'settings.email',
        value: emailVal,
        toggleEdit: () => dispatch(toggleEdit(EMAIL_FIELD)),
        isEdit: isEditEmail,
        fieldName: EMAIL_FIELD,
      },
    ],
    [dispatch, emailVal, firstNameVal, isEditEmail, isEditFirstName, isEditLastName, lastNameVal],
  );

  return (
    <Box>
      <Typography fontSize={20} fontFamily="WFVisualSans" pb={1}>
        {t('settings.personalDetails')}
      </Typography>

      <Box m={0} p={0}>
        {detailsConfig.map((details, index) => (
          <Fragment key={details.title}>
            {!details.isEdit && <UserDetail {...details} />}
            {details.isEdit && (
              <EditField title={details.title} fieldName={details.fieldName} onClick={details.toggleEdit} />
            )}
            {index !== detailsConfig.length - 1 && <Divider sx={{ my: 1.5 }} />}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default memo(PersonalDetails);
