import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesListQuery } from '@api/api';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSubscription } from '@hooks/useSubscription';
import CalendarView from '@pages/Invoices/components/CalendarView';
import SpeedDialButton from '@pages/Invoices/components/CalendarView/SpeedDialButton';
import ControlsBar from '@pages/Invoices/components/ControlsBar';
import InvoicesTable from '@pages/Invoices/components/TableView/InvoicesTable';
import { useInvoicesContext } from '@pages/Invoices/context';
import { VIEW } from '@pages/Invoices/enums';
import PreviewInvoice from '@pages/Invoices/modals/PreviewInvoice';
import { getEndOfMonth, getStartOfMonth } from '@utils/formatTime';

import { Box } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { t } = useTranslation();
  const { isLoadingSubscription } = useSubscription();
  const {
    view,
    handleChangeView,
    selectedMonth,
    isLoadingUserData,
    selectedParticipants,
    setSelectedMonth,
    handlePickDate,
  } = useInvoicesContext();

  const { downSm } = useBreakpoints();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useApiInvoicesListQuery({
    ...(view === VIEW.TABLE && { limit, offset: page * limit }),
    ...(view === VIEW.CALENDAR && {
      orderBy: 'created_at',
      order: 'asc',
    }),
    ...(view === VIEW.CALENDAR && {
      serviceStartDate: getStartOfMonth(selectedMonth),
      serviceEndDate: getEndOfMonth(selectedMonth),
    }),
    ...(view === VIEW.CALENDAR &&
      selectedParticipants.length > 0 && {
        participants: selectedParticipants
          .map(participant => `${participant.first_name} ${participant.last_name}`)
          .join(','),
      }),
  });

  const isLoading = [isLoadingInvoices, isFetching, isLoadingSubscription, isLoadingUserData].some(Boolean);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoicesReceipts')}</title>
      </Helmet>

      <ControlsBar view={view} onChange={handleChangeView} />
      <Box aria-label="invoices page" display="flex" flexDirection="column" height="100%">
        <Box height="100%">
          {view === VIEW.TABLE && (
            <InvoicesTable
              invoices={invoicesData}
              isLoading={isLoading}
              handlePageChange={handlePaginationModelChange}
              paginationModel={{ page, pageSize: limit }}
            />
          )}
          {view === VIEW.CALENDAR && <CalendarView invoicesData={invoicesData} isLoading={isLoading} />}
        </Box>
        <PreviewInvoice />
        {view === VIEW.CALENDAR && downSm && (
          <SpeedDialButton handleSetMonth={setSelectedMonth} handlePickDate={handlePickDate} />
        )}
      </Box>
    </>
  );
};

export default memo(Invoices);
