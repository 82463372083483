import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesEventsListApiResponse } from '@api/api';
import { useEvents } from '@pages/InvoiceDetails/components/TasksTab/hooks/useEvents';
import { StyledSecondaryDueDateChip, StyledTimelineActionRow } from '@pages/InvoiceDetails/components/TasksTab/styled';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { convertIsoToReadable } from '@utils/formatTime';

import AddIcon from '@mui/icons-material/Add';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { StyledTypographyContrast } from '@/shared/styles';

const TimelineActions: FC<{ eventsList?: ApiInvoicesEventsListApiResponse }> = ({ eventsList }) => {
  const { t } = useTranslation();
  const { deleteTimelineEntry, addEvent, isClaimed, isPaid } = useEvents(eventsList);

  const isClaimedId = eventsList?.find(ev => ev.type === 'claim_submitted')?.id;
  const isPaidId = eventsList?.find(ev => ev.type === 'invoice_paid')?.id;
  const isPaymentDue = eventsList?.find(ev => ev.type === 'payment_due_date');

  return (
    <Box
      width={{
        xs: '100%',
        md: '366px',
      }}
      minWidth={{
        xs: '358px',
      }}
    >
      <StyledTimelineActionRow width="100%" isChecked={isClaimed}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isClaimed}
              onChange={event => {
                if (event.target.checked) {
                  return addEvent('claim_submitted');
                }
                if (isClaimedId) {
                  deleteTimelineEntry({
                    eventId: isClaimedId,
                    eventTitle: t('dashboard.tasks.checkboxes.submitClaimLabel'),
                    taskLabel: t('dashboard.tasks.claimSubmitted'),
                  });
                }
              }}
            />
          }
          label={
            <StyledTypographyContrast fontFamily="WFVisualSans">
              {t('dashboard.tasks.checkboxes.submitClaimLabel')}
            </StyledTypographyContrast>
          }
        />
      </StyledTimelineActionRow>
      <StyledTimelineActionRow
        width="100%"
        isChecked={isPaid}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isPaid}
              onChange={event => {
                if (event.target.checked) {
                  return addEvent('invoice_paid');
                }
                if (isPaidId) {
                  deleteTimelineEntry({
                    eventId: isPaidId,
                    eventTitle: t('dashboard.tasks.checkboxes.payProviderLabel'),
                    taskLabel: t('dashboard.tasks.providerPaid'),
                  });
                }
              }}
            />
          }
          label={
            <StyledTypographyContrast fontFamily="WFVisualSans">
              {t('dashboard.tasks.checkboxes.payProviderLabel')}
            </StyledTypographyContrast>
          }
        />
        {/* Button or Chip */}
        {isPaymentDue?.date && (
          <StyledSecondaryDueDateChip
            label={`${t('common.due')} ${convertIsoToReadable(isPaymentDue.date, { fullYear: true, monthFormat: 'MMM' })}`}
            color="secondary"
            size="small"
            variant="filled"
          />
        )}
        {!isPaymentDue?.date && (
          <StyledTextButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="text"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => addEvent('payment_due_date')}
          >
            <Typography fontSize={13}>{t('dashboard.tasks.buttons.addDueDate')}</Typography>
          </StyledTextButton>
        )}
      </StyledTimelineActionRow>
    </Box>
  );
};

export default memo(TimelineActions);
