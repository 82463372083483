enum Tag {
  USER = 'User',
  INVOICE = 'Invoice',
  INVOICE_LIST = 'InvoiceList',
  INVOICE_CHANGE = 'Invoice change',
  INVOICE_NOTES_LIST = 'Invoice notes list',
  INVOICE_EVENTS_LIST = 'Invoice events list',
  PARTICIPANTS_LIST = 'Participants list',
}

export default Tag;
