import { ParticipantTypeEnum } from '@api/api';
import { ParticipantRole } from '@pages/Settings/modals/AddParticipant/enums';

const rolesMap: Record<ParticipantRole, ParticipantTypeEnum> = {
  [ParticipantRole.Myself]: 'user',
  [ParticipantRole.Other]: 'nominee_child_representative',
  [ParticipantRole.Nominee]: 'nominee',
  [ParticipantRole.ChildRepresentative]: 'child_representative',
};

export const getRoleType = (enumVal: ParticipantRole): ParticipantTypeEnum => rolesMap[enumVal];
