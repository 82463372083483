import { Dispatch, FC, memo, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import SelectDatePopper from '@pages/Invoices/components/CalendarView/SelectDatePopper';
import { SelectDateModalId } from '@pages/Invoices/modals/SelectDateModal';
import { StyledButtonGroup, StyledNoShadowButton } from '@pages/Invoices/styled';
import { getTodayIso } from '@pages/Invoices/utils';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface DateSelectorGroupProps {
  handleSetMonth: Dispatch<SetStateAction<string>>;
  handlePickDate: (date: string) => void;
}

const DateSelectorGroup: FC<DateSelectorGroupProps> = ({ handleSetMonth, handlePickDate }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpenDateModal = async () => {
    await NiceModal.show(SelectDateModalId, { handleSelectDate: handleSetMonth, handlePickDate });
  };

  const handleOpenPopper = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };

  return (
    <Box>
      {downSm && (
        <Box display="flex" gap={2}>
          <StyledNoShadowButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            disableElevation
            variant="outlined"
            color="primary"
            sx={{ width: '77px' }}
            onClick={() => {
              handleSetMonth(getTodayIso());
              handlePickDate(getTodayIso());
            }}
          >
            <Typography fontSize={14}>{t('dashboard.today')}</Typography>
          </StyledNoShadowButton>
          <IconButton disableRipple disableTouchRipple disableFocusRipple color="primary" onClick={handleOpenDateModal}>
            <CalendarTodayIcon />
          </IconButton>
        </Box>
      )}
      {!downSm && (
        <StyledButtonGroup disableElevation variant="outlined" color="primary">
          <Button
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="outlined"
            color="primary"
            onClick={() => {
              handleSetMonth(getTodayIso());
              handlePickDate(getTodayIso());
            }}
          >
            <Typography fontSize={14}>{t('dashboard.today')}</Typography>
          </Button>
          <Button
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="outlined"
            color="primary"
            endIcon={<EventIcon />}
            onClick={handleOpenPopper}
          >
            <Typography fontSize={14}>{t('dashboard.selectDate')}</Typography>
          </Button>
        </StyledButtonGroup>
      )}
      <SelectDatePopper
        open={open}
        anchorEl={anchorEl}
        handleOpen={handleOpenPopper}
        handleSelectDate={handleSetMonth}
        handlePickDate={handlePickDate}
      />
    </Box>
  );
};

export default memo(DateSelectorGroup);
