import { FC, Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import ManageAction from '@pages/Settings/components/AccountTab/components/ManageAction';
import { ChangePasswordModalId } from '@pages/Settings/modals/ChangePassword';

import { Box, Divider, Typography } from '@mui/material';

const ManageAccount: FC = () => {
  const { t } = useTranslation();

  const actions = useMemo(
    () => [
      {
        title: 'settings.changePasswordTitle',
        subtitle: 'settings.changePasswordSubtitle',
        onClick: async () => {
          await NiceModal.show(ChangePasswordModalId);
        },
      },
      {
        title: 'settings.deleteAccountTitle',
        subtitle: 'settings.deleteAccountSubtitle',
        onClick: () => {}, // todo render only delete page
      },
    ],
    [],
  );

  return (
    <Box>
      <Typography fontFamily="WFVisualSans" fontSize={20}>
        {t('settings.manageAccount')}
      </Typography>

      <Box mt={3}>
        {actions.map((action, index) => (
          <Fragment key={action.title}>
            <ManageAction {...action} />
            {index !== actions.length - 1 && <Divider sx={{ my: 1.5 }} />}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default memo(ManageAccount);
