import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantRead } from '@api/api';
import ParticipantInfo from '@pages/Settings/components/AccountTab/components/Participants/ParticipantInfo';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { MAX_NUMBER_OF_PARTICIPANTS } from '@pages/Settings/constants';
import { useParticipants } from '@pages/Settings/hooks/useParticipants';

import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';

interface ParticipantsListProps {
  data?: ParticipantRead[];
}

const ActiveParticipantsList: FC<ParticipantsListProps> = ({ data }) => {
  const { t } = useTranslation();
  const { handleShowAddParticipantModal } = useParticipants();

  const isParticipantPresent = !!data?.find(participant => participant.type === 'user');

  return (
    <Box>
      <Box>
        {data?.map(({ id, first_name, last_name, type, is_active }) => (
          <Fragment key={id}>
            {is_active && <ParticipantInfo key={id} firstName={first_name} lastName={last_name} type={type} id={id} />}
          </Fragment>
        ))}
      </Box>
      {data && data.length >= MAX_NUMBER_OF_PARTICIPANTS && (
        <Typography fontSize={14} fontWeight={400} color="textSecondary">
          {t('settings.participants.limitReached')}
        </Typography>
      )}
      {data && data.length < MAX_NUMBER_OF_PARTICIPANTS && (
        <StyledTextButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          endIcon={<AddIcon color="primary" fontSize="small" />}
          onClick={() => handleShowAddParticipantModal(!isParticipantPresent)}
        >
          <Typography fontSize={13} color="primary">
            {t('settings.participants.actions.addParticipant')}
          </Typography>
        </StyledTextButton>
      )}
    </Box>
  );
};

export default memo(ActiveParticipantsList);
