import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ApiInvoicesEventsDestroyApiArg } from '@api/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ManualEventActions } from '@pages/InvoiceDetails/components/TasksTab/types';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledIconButton, StyledModal } from '../styled';

interface DeleteEventModalProps {
  eventTitle: string;
  deleteMutation: (payload: ApiInvoicesEventsDestroyApiArg) => Promise<void>;
  invoiceId: number;
  id: number;
  eventType: ManualEventActions;
  taskLabel: string;
}

const DeleteEventModal = NiceModal.create(
  ({ eventTitle, deleteMutation, invoiceId, id, taskLabel }: DeleteEventModalProps) => {
    const { t } = useTranslation();
    const modal = useModal();

    const [isLoading, setIsLoading] = useState(false);

    const handleConfirmClick = async () => {
      setIsLoading(true);

      await deleteMutation({ invoiceId, id });
      modal.resolve(true);
      setIsLoading(false);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="xs" onClose={handleCancelClick}>
        <Box padding="20px 25px 30px">
          <Box textAlign="end" pb={2}>
            <StyledIconButton disableRipple onClick={handleCancelClick}>
              <CloseIcon />
            </StyledIconButton>
          </Box>

          <Typography fontWeight={500} maxWidth={290}>
            <Trans i18nKey={t('dashboard.tasks.modal.deleteConfirmTitle')} values={{ eventTitle }} />
          </Typography>

          <Typography fontWeight={400} maxWidth={290} mt={2}>
            <Trans
              i18nKey={t('dashboard.tasks.modal.deleteConfirmText')}
              values={{ taskLabel: t(taskLabel) }}
              components={{ bold: <strong /> }}
            />
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="space-between" mt="36px">
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              color="primary"
              onClick={handleCancelClick}
            >
              {t('common.cancel')}
            </StyledTextButton>
            <LoadingButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="contained"
              color="primary"
              loading={isLoading}
              onClick={handleConfirmClick}
              sx={{ height: 42, width: 111, boxShadow: 2 }}
            >
              {t('common.delete')}
            </LoadingButton>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const DeleteEventModalId = 'DeleteEventModal';

NiceModal.register(DeleteEventModalId, DeleteEventModal);
