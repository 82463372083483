import { useState } from 'react';
import { SettingsTab } from '@pages/Settings/constants/enums';

export const useSettingsTab = () => {
  const [activeTab, setActiveTab] = useState(SettingsTab.Account);

  const handleTabChange = (tab: SettingsTab) => setActiveTab(tab);

  return {
    activeTab,
    handleTabChange,
  };
};
