import * as yup from 'yup';

export const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
});

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().minMax(8, 25).containsDigit().containsUppercase().required(),
  new_password1: yup.string().minMax(8, 25).containsDigit().containsUppercase().required(),
  new_password2: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('new_password1')], 'Your passwords do not match.'),
});

export const editParticipantNameSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
});

export const addAnotherParticipantSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
});
