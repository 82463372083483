import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useUser } from '@hooks/api/useUser';
import { useParticipants } from '@pages/Settings/hooks/useParticipants';

const SettingsContext = createContext<ReturnType<typeof useSettingsContextValue> | null>(null);

const useSettingsContextValue = () => {
  const { data: userData, isLoading: isUserLoading, userName } = useUser();
  const { participantsData, isLoadingParticipantsData } = useParticipants();

  const isLoading = [isUserLoading, isLoadingParticipantsData].some(Boolean);

  return {
    userData,
    isLoading,
    participantsData,
    userName,
  };
};

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('useSettingsContext must be inside SettingsProvider');
  return context;
};

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useSettingsContextValue();
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
