import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { SettingsTab } from '@pages/Settings/constants/enums';
import { StyledSettingsHeaderBox, StyledSettingsHeaderSelectionBox } from '@pages/Settings/styled';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';

const SettingsHeader: FC<{ handleTabChange: (tab: SettingsTab) => void; activeTab: SettingsTab }> = ({
  handleTabChange,
  activeTab,
}) => {
  const { t } = useTranslation();
  const { downMd } = useBreakpoints();

  const isAccountSelected = activeTab === SettingsTab.Account;

  return (
    <StyledSettingsHeaderBox
      m={0}
      py="19.5px"
      px={{
        xs: 2,
        sm: 2.5,
        md: '30px',
        lg: 10,
      }}
      style={{
        position: downMd ? 'sticky' : 'relative',
        top: downMd ? 100 : 'auto',
      }}
      height={75}
      display="flex"
      gap="58px"
      width="100%"
    >
      <StyledSettingsHeaderSelectionBox
        py={1}
        px="10px"
        onClick={() => handleTabChange(SettingsTab.Account)}
        isSelected={isAccountSelected}
        display="flex"
        gap={1.5}
        justifyContent="center"
        width={140}
        ml={2.5}
      >
        <PersonIcon color={isAccountSelected ? 'primary' : 'action'} />
        <Typography fontWeight={400}>{t('settings.account')}</Typography>
      </StyledSettingsHeaderSelectionBox>
      <StyledSettingsHeaderSelectionBox
        py={1}
        px="10px"
        onClick={() => handleTabChange(SettingsTab.Billing)}
        isSelected={!isAccountSelected}
        display="flex"
        gap={1.5}
        justifyContent="center"
        width={120}
      >
        <CreditCardIcon color={!isAccountSelected ? 'primary' : 'action'} />
        <Typography fontWeight={400}>{t('settings.billing')}</Typography>
      </StyledSettingsHeaderSelectionBox>
    </StyledSettingsHeaderBox>
  );
};

export default memo(SettingsHeader);
