import { FC, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BillingPeriod } from '@constants/enums';
import { ROUTING } from '@constants/routing';
import { useBilling } from '@hooks/api/useBilling';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useLogout } from '@hooks/useLogout';
import { useSubscription } from '@hooks/useSubscription';
import Card from '@pages/SubscribePage/components/Card';
import { StyledProceedButton } from '@pages/SubscribePage/components/styled';
import { cardsConfig } from '@pages/SubscribePage/config';
import { StyledBoxContainer, StyledLogoutButton, StyledTextButton } from '@pages/SubscribePage/styled';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Typography } from '@mui/material';

import { StyledTypographyPrimaryContrast } from '@/shared/styles';

const SubscribePage: FC = () => {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.YEAR);

  const { t } = useTranslation();
  const { data: subscriptionData, isLoadingSubscription } = useSubscription();
  const { logout } = useLogout();
  const { handleSubscribe, isLoadingBilling } = useBilling();
  const { downMd } = useBreakpoints();
  const navigate = useNavigate();

  const trialEnded = subscriptionData?.status === 'inactive';
  // const isSubscribed = subscriptionData?.subscription_type && subscriptionData?.days_left;
  const isSamePackage = subscriptionData?.subscription_type === billingPeriod;

  const handlePeriodChange = (period: BillingPeriod) => {
    setBillingPeriod(period);
  };

  const handleProceed = () => handleSubscribe({ period: billingPeriod });

  if (isLoadingSubscription) return null;

  // ToDo temporary disable this check
  // if (isSubscribed) {
  //   navigate(ROUTING.ROOT, { replace: true });
  // }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.subscribePage')}</title>
      </Helmet>
      <StyledBoxContainer width="auto" minHeight="100vh">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          pb={{ xs: '93px', sm: '263px', md: '357px' }}
          pl={{ xs: 2, sm: 2.5, md: '31px', lg: '169px' }}
          pr={{ xs: '17px', sm: 2.5, md: '30px', lg: '170px' }}
          pt="100px"
          position='relative'
        >
          <Box
            position='absolute'
            display='flex'
            gap={1}
            alignItems='center'
            top={44}
            left={{
              xs: 16,
              sm: 20,
              md: 31,
              lg: 60
            }}
            color='white'
          >
            <StyledTextButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              variant='text'
              onClick={() => navigate(ROUTING.ROOT)}
              startIcon={<ChevronLeftIcon />}
            >{t('common.back')}</StyledTextButton>
          </Box>
          {/* NOT ENDED TRIAL */}
          {!trialEnded && (
            <>
              <StyledTypographyPrimaryContrast fontWeight={500} fontSize={38} fontFamily="WFVisualSans">
                {t('dashboard.subscribePage.title')}
              </StyledTypographyPrimaryContrast>

              <StyledTypographyPrimaryContrast
                mt={2.5}
                fontWeight={500}
                fontSize={{ sm: 28, xs: 24 }}
                fontFamily="WFVisualSans"
              >
                {t('dashboard.subscribePage.subtitle')}
              </StyledTypographyPrimaryContrast>
            </>
          )}
          {/* TRIAL ENDED */}
          {trialEnded && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                maxWidth="924px"
                position="relative"
              >
                <StyledTypographyPrimaryContrast fontWeight={500} fontSize={38} fontFamily="WFVisualSans">
                  {t('dashboard.subscribePage.trialEnded.title')}
                </StyledTypographyPrimaryContrast>

                <Box position="absolute" left={-25} top={-55}>
                  <StyledLogoutButton variant="text" endIcon={<LogoutIcon />} onClick={logout}>
                    <Typography fontWeight={500} fontSize={14}>
                      {t('auth.logout')}
                    </Typography>
                  </StyledLogoutButton>
                </Box>
              </Box>
              <StyledTypographyPrimaryContrast
                mt={2.5}
                fontWeight={500}
                fontSize={28}
                fontFamily="WFVisualSans"
                maxWidth={450}
              >
                {t('dashboard.subscribePage.trialEnded.subtitle')}
              </StyledTypographyPrimaryContrast>
            </>
          )}

          <StyledTypographyPrimaryContrast mt={6} fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
            {t('dashboard.subscribePage.billingCycleTitle')}
          </StyledTypographyPrimaryContrast>

          <StyledTypographyPrimaryContrast mt={1} mb={3} fontWeight={400} fontSize={16}>
            {t('dashboard.subscribePage.billingCycleSubtitle')}
          </StyledTypographyPrimaryContrast>

          {/* CARDS */}
          <Box display="flex" gap={3} flexDirection={{ md: 'row', xs: 'column' }} maxWidth="924px">
            {cardsConfig.map(card => (
              <Card
                key={card.id}
                config={card.config}
                plan={card.plan}
                isSelected={card.plan === billingPeriod}
                onPeriodChange={handlePeriodChange}
              />
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection={!downMd ? 'row' : 'column'}
            justifyContent={billingPeriod === BillingPeriod.YEAR ? 'flex-start' : 'flex-end'}
            maxWidth={840}
            mt={4}
            gap={3}
          >
            {!downMd && (
              <>
                <Box flexGrow={1} maxWidth={408}>
                  {billingPeriod === BillingPeriod.YEAR && (
                    <StyledProceedButton
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleProceed}
                      loading={isLoadingBilling}
                      disabled={isSamePackage}
                    >
                      <StyledTypographyPrimaryContrast fontSize={15} fontWeight={500}>
                        {isSamePackage
                          ? t('dashboard.subscribePage.samePackage')
                          : t('dashboard.subscribePage.proceedToPayment')}
                      </StyledTypographyPrimaryContrast>
                    </StyledProceedButton>
                  )}
                </Box>
                <Box flexGrow={1} maxWidth={408}>
                  {billingPeriod === BillingPeriod.MONTH && (
                    <StyledProceedButton
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleProceed}
                      loading={isLoadingBilling}
                      disabled={isSamePackage}
                    >
                      <StyledTypographyPrimaryContrast fontSize={15} fontWeight={500}>
                        {isSamePackage
                          ? t('dashboard.subscribePage.samePackage')
                          : t('dashboard.subscribePage.proceedToPayment')}
                      </StyledTypographyPrimaryContrast>
                    </StyledProceedButton>
                  )}
                </Box>
              </>
            )}
            {downMd && (
              <StyledProceedButton
                color="primary"
                fullWidth
                size="large"
                onClick={handleProceed}
                loading={isLoadingBilling}
                disabled={isSamePackage}
              >
                <StyledTypographyPrimaryContrast fontSize={15} fontWeight={500}>
                  {isSamePackage
                    ? t('dashboard.subscribePage.samePackage')
                    : t('dashboard.subscribePage.proceedToPayment')}
                </StyledTypographyPrimaryContrast>
              </StyledProceedButton>
            )}
          </Box>
        </Box>
      </StyledBoxContainer>
    </>
  );
};

export default memo(SubscribePage);
