import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceEventTypeEnum as TypeEnum } from '@api/api';
import { useMenuActions } from '@hooks/useMenuActions';
import {
  StyledActionsMenu,
  StyledActionsMenuItem,
  StyledIconButton,
} from '@pages/InvoiceDetails/components/NotesTab/styled';
import { useEvents } from '@pages/InvoiceDetails/components/TasksTab/hooks/useEvents';
import {
  StyledEventsPaymentDueChip,
  StyledTimelineConnector,
  StyledTimelineDot,
} from '@pages/InvoiceDetails/components/TasksTab/styled';
import { EventIconType } from '@pages/InvoiceDetails/components/TasksTab/types';
import { ICONS_SVG_MAP } from '@pages/InvoiceDetails/components/TasksTab/utils';
import { convertIsoToReadable } from '@utils/formatTime';

import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TimelineContent, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { StyledTypographyContrast } from '@/shared/styles';

const InvoiceEventTimeline: FC<{
  iconType: EventIconType;
  eventDate: string | null | undefined;
  isLast: boolean;
  title?: string; // if payment due then show Chip
  chipTitle?: string;
  detailsFieldTitle?: string | null;
  detailsFieldDescription?: string | null;
  isSubmitted?: boolean;
  isAutogenerated?: boolean;
  isEditable?: boolean;
  id: number;
  loadingDelete?: boolean;
  eventTitle?: string | null;
  taskLabel?: string | null;
}> = ({
  iconType,
  eventDate,
  title,
  detailsFieldTitle,
  detailsFieldDescription,
  chipTitle,
  isLast,
  isSubmitted,
  isAutogenerated,
  isEditable,
  id,
  loadingDelete,
  eventTitle,
  taskLabel,
}) => {
  const { t } = useTranslation();
  const IconComponent = ICONS_SVG_MAP[iconType];
  const { anchorEl, open, handleClose, handleOpen } = useMenuActions();
  const { deleteTimelineEvent, editEvent } = useEvents();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <StyledTimelineDot>
          <IconComponent color="primary" />
        </StyledTimelineDot>
        <StyledTimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box minHeight={isLast ? 100 : 140} pt="6px" pb={isLast ? 0 : 2.5}>
          <Box mb="12px">
            <Typography color={alpha('#000', 0.6)} fontWeight={400} pb="6px">
              {eventDate
                ? convertIsoToReadable(eventDate, {
                    fullYear: true,
                    dayFormat: 'd',
                    monthFormat: 'MMMM',
                  })
                : t('dashboard.tasks.noDateRecorded')}
            </Typography>
            {title && (
              <StyledTypographyContrast fontSize={18} fontFamily="WFVisualSans">
                {eventTitle || t(title)}
              </StyledTypographyContrast>
            )}
            {!title && chipTitle && (
              <Box my="11.5px">
                <StyledEventsPaymentDueChip
                  label={
                    <Typography fontSize={13} fontWeight={400}>
                      {t(chipTitle)}
                    </Typography>
                  }
                  color="secondary"
                  size="medium"
                  variant="filled"
                />
              </Box>
            )}
          </Box>
          {detailsFieldTitle && (
            <Box my="11.5px">
              <Typography color={alpha('#000', 0.6)} fontWeight={400} fontSize={14} pb="1px">
                {t(detailsFieldTitle)}
              </Typography>
              <Typography fontWeight={400} fontSize={14}>
                {detailsFieldDescription || t('common.none')}
              </Typography>
            </Box>
          )}
          {isSubmitted && (
            <Box display="flex" gap="4px" mt={1.5}>
              <Typography fontWeight={400} fontSize={14}>
                {t('dashboard.tasks.submittedWithWizard')}
              </Typography>
              <AutoFixHighTwoToneIcon fontSize="small" color="primary" />
            </Box>
          )}
          {isAutogenerated && (
            <Box display="flex" gap="4px" my={1.5}>
              <Typography fontWeight={400} fontSize={14}>
                {t('dashboard.tasks.generatedFromInvoice')}
              </Typography>
              <AutoAwesomeTwoToneIcon fontSize="small" color="primary" />
            </Box>
          )}
          {isEditable && (
            <>
              <StyledIconButton disableRipple disableFocusRipple disableTouchRipple onClick={handleOpen}>
                <MoreHorizIcon color="primary" />
              </StyledIconButton>

              <StyledActionsMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'actions-button' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <StyledActionsMenuItem
                  onClick={() => {
                    handleClose();
                    editEvent({
                      eventId: id,
                      eventName: eventTitle || t(`dashboard.tasks.modal.titles.${iconType}`),
                      eventType: iconType as TypeEnum,
                      eventDate,
                      eventDetails: detailsFieldDescription,
                    });
                  }}
                >
                  <EditIcon color="action" />
                  <Typography fontWeight={400}>{t('common.edit')}</Typography>
                </StyledActionsMenuItem>
                <Divider />
                <StyledActionsMenuItem
                  onClick={() => {
                    handleClose();
                    deleteTimelineEvent({
                      eventId: id,
                      eventTitle: eventTitle || t(`dashboard.tasks.modal.titles.${iconType}`),
                      taskLabel: taskLabel || '',
                    });
                  }}
                  disabled={loadingDelete}
                >
                  <DeleteIcon color="action" />
                  <Typography fontWeight={400}>{t('common.delete')}</Typography>
                </StyledActionsMenuItem>
              </StyledActionsMenu>
            </>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default memo(InvoiceEventTimeline);
