import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesEventsCreateApiArg } from '@api/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ManualEventActions } from '@pages/InvoiceDetails/components/TasksTab/types';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, FormHelperText, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@components/LoadingButton';

import { StyledAddModalRegularTextField, StyledIconButton, StyledModal } from '../styled';

interface AddEventModalProps {
  eventType: ManualEventActions;
  createMutation: (payload: ApiInvoicesEventsCreateApiArg) => Promise<void>;
  invoiceId: number;
}

const AddEventModal = NiceModal.create(({ eventType, createMutation, invoiceId }: AddEventModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [nameValue, setNameValue] = useState<string>('');
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [detailsValue, setDetailsValue] = useState<string>('');

  const isCustomEvent = eventType === 'custom_event';
  const isPaymentDueEvent = eventType === 'payment_due_date';

  const handleConfirmClick = async () => {
    setIsLoading(true);
    const payload = {
      invoiceId,
      invoiceEventRequest: {
        type: eventType,
        is_manual: true,
        ...(dateValue && { date: dateValue.toISOString() }),
        ...(detailsValue && { details: detailsValue }),
        ...(isCustomEvent && nameValue && { name: nameValue }),
      },
    };

    await createMutation(payload);
    modal.resolve(true);
    setIsLoading(false);
    modal.remove();
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size="xs" onClose={handleCancelClick}>
      <Box padding="20px 25px 30px">
        <Box textAlign="end" pb={2}>
          <StyledIconButton disableRipple onClick={handleCancelClick}>
            <CloseIcon />
          </StyledIconButton>
        </Box>
        <Typography fontSize={20} fontFamily="WFVisualSans" mb={2}>
          {t(`dashboard.tasks.modal.titles.${eventType}`)}
        </Typography>

        {isCustomEvent && (
          <Box mb="21px" display="flex" flexDirection="column" gap="26px">
            <StyledAddModalRegularTextField
              aria-label={`${eventType} name field`}
              variant="outlined"
              label={t('common.inputs.title')}
              type="text"
              value={nameValue}
              onWheel={e => (e.target as HTMLElement).blur()}
              onChange={e => {
                setNameValue(e.target.value);
              }}
            />
            <Divider />
          </Box>
        )}

        {!isPaymentDueEvent && (
          <Typography fontSize={14} fontWeight={400}>
            {t(`dashboard.tasks.modal.subtitles.${isCustomEvent ? eventType : 'optional'}`)}
          </Typography>
        )}

        <Box mt={isCustomEvent ? '21px' : '25px'}>
          <DatePicker
            value={dateValue}
            onChange={newDate => setDateValue(newDate)}
            label={t(`dashboard.tasks.modal.datesField.${eventType}`)}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              textField: () => ({
                sx: {
                  '& .MuiInputBase-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '& .MuiFormLabel-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '&.MuiFormControl-root': { width: '100%' },
                },
                variant: 'outlined',
                color: 'primary',
              }),
            }}
          />
          <FormHelperText style={{ marginLeft: '15px' }}>{t('common.optional')}</FormHelperText>
        </Box>

        {!isPaymentDueEvent && (
          <Box mt="25px">
            <StyledAddModalRegularTextField
              aria-label={`${eventType} details field`}
              variant="outlined"
              value={detailsValue}
              label={t(`dashboard.tasks.modal.detailsField.${eventType}`)}
              type="text"
              onWheel={e => (e.target as HTMLElement).blur()}
              onChange={e => {
                setDetailsValue(e.target.value);
              }}
            />
            <FormHelperText style={{ marginLeft: '15px' }}>{t('common.optional')}</FormHelperText>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center" mt="28px">
          <LoadingButton
            fullWidth
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            loading={isLoading}
            color="primary"
            onClick={handleConfirmClick}
            type="submit"
            sx={{ maxHeight: '42px', boxShadow: 2 }}
            disabled={eventType === 'custom_event' && !nameValue}
          >
            <Typography>
              {eventType === 'custom_event' ? t('dashboard.tasks.modal.createTimelineEntry') : t('common.save')}
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const AddEventModalId = 'AddEventModal';

NiceModal.register(AddEventModalId, AddEventModal);
