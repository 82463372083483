import { Box, BoxProps, Chip, ChipProps, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHeaderBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.background.detailsBackground,
}));

export const StyledToggleBox = styled(Box)<BoxProps>(() => ({
  minHeight: '68px',
}));

export const StyledShadowBottomBox = styled(Box)<BoxProps>({
  position: 'relative',
  boxShadow: '0 3px 5px -1px gray',
});

export const StyledReceivedChip = styled(Chip)<ChipProps>(({ theme }) => ({
  border: '1px solid #02579B',
  backgroundColor: '#F4FEFF',
  padding: '10px 1px',
  height: 35,
  color: theme.colors.secondary.contrast,
  '& .MuiSvgIcon-root': {
    color: theme.colors.secondary.contrast,
  },
  '& .MuiChip-label': {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px'
  },
  '&:hover': {
    backgroundColor: '#F4FEFF',
  },
}));

export const StyledAutofilledChip = styled(Chip)<ChipProps>(({ theme }) => ({
  border: `1px solid ${theme.colors.primary.dark}`,
  backgroundColor: '#F9F4FF',
  padding: '10px 1px',
  height: 35,
  '& .MuiChip-label': {
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  '&:hover': {
    backgroundColor: '#F9F4FF',
  },
}));

export const StyledInnerCircleBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '50%',
  paddingTop: '2px',
  border: `1px solid ${theme.colors.primary.dark}`,
  height: 24,
  width: 24,
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontWeight: 600,
  backgroundColor: '#E0C8FF',
  color: '#25185F !important',
}));

export const StyledOuterCircleBox = styled(Box)<BoxProps>(({ theme }) => ({
  border: `1px solid ${theme.colors.info.dark}`,
  backgroundColor: '#F4FEFF',
  '& svg': {
    color: theme.colors.secondary.contrast,
  },
  borderRadius: '50%',
  height: 35,
  width: 35,
  paddingLeft: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  width: '100%',
  '& .MuiButtonBase-root': {
    paddingTop: 9,
    paddingBottom: 9,
  }
}));
