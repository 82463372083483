/* eslint-disable no-param-reassign */
import { EMAIL_FIELD, FIRSTNAME_FIELD, LASTNAME_FIELD } from '@pages/Settings/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SettingsFieldName } from '@/globalTypes';

interface SettingsState {
  isEditFirstName: boolean;
  isEditLastName: boolean;
  isEditEmail: boolean;
}

const initialState: SettingsState = {
  isEditFirstName: false,
  isEditLastName: false,
  isEditEmail: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleEdit: (state, action: PayloadAction<SettingsFieldName>) => {
      switch (action.payload) {
        case FIRSTNAME_FIELD:
          state.isEditFirstName = !state.isEditFirstName;
          break;
        case LASTNAME_FIELD:
          state.isEditLastName = !state.isEditLastName;
          break;
        case EMAIL_FIELD:
          state.isEditEmail = !state.isEditEmail;
          break;
        default:
          break;
      }
    },
  },
});

export const { toggleEdit } = settingsSlice.actions;
export default settingsSlice.reducer;
