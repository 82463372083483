import { Dispatch, FC, memo, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledAnchoredPaper,
  StyledCenteredIconButton,
  StyledOkBtn,
  StyledPopper,
  StyledTextButton,
} from '@pages/Invoices/styled';
import { formatDate } from '@utils/formatTime';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Box, Fade, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface SelectDatePopperProps {
  handleSelectDate: Dispatch<SetStateAction<string>>;
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handlePickDate: (date: string) => void;
}

const SelectDatePopper: FC<SelectDatePopperProps> = ({
  handleSelectDate,
  handleOpen,
  open,
  anchorEl,
  handlePickDate,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);

  const handleConfirmClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (date) {
      handleSelectDate(formatDate(date));
      handlePickDate(formatDate(date));
    }
    handleOpen(e);
    setDate(null);
  };

  const handleChangeDate = (newDate: Date | null) => {
    setDate(newDate);
  };

  return (
    <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <StyledAnchoredPaper elevation={6}>
            <Box padding="20px">
              <Box display="flex" justifyContent="space-between" height={34}>
                <Typography>{t('dashboard.selectDate')}</Typography>

                <StyledCenteredIconButton disableRipple onClick={handleOpen}>
                  <CloseIcon />
                </StyledCenteredIconButton>
              </Box>

              <Box mt={2}>
                <DatePicker
                  value={date}
                  onChange={handleChangeDate}
                  label={t('dashboard.selectDate')}
                  slots={{
                    openPickerIcon: EventIcon,
                  }}
                  slotProps={{
                    textField: () => ({
                      sx: {
                        '& .MuiInputBase-root': {
                          fontWeight: 400,
                          fontSize: '16px',
                        },
                        '& .MuiFormLabel-root': {
                          fontWeight: 400,
                          fontSize: '16px',
                        },
                        '&.MuiFormControl-root': { width: '100%' },
                      },
                      variant: 'outlined',
                      color: 'primary',
                    }),
                  }}
                />
              </Box>
              <Box display="flex" mt={2.5} justifyContent="end" height={36}>
                <StyledTextButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  variant="text"
                  onClick={handleOpen}
                >
                  <Typography fontSize={14}>{t('common.cancel')}</Typography>
                </StyledTextButton>
                <StyledOkBtn disableRipple disableFocusRipple disableTouchRipple onClick={handleConfirmClick}>
                  <Typography fontSize={14}>{t('common.okBtn')}</Typography>
                </StyledOkBtn>
              </Box>
            </Box>
          </StyledAnchoredPaper>
        </Fade>
      )}
    </StyledPopper>
  );
};

export default memo(SelectDatePopper);
