import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDropdown from '@pages/InvoiceDetails/components/DetailsTab/components/AlertDropdown';
import { MISSING_CATEGORY_FIELD, MISSING_REASON_FIELD } from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { getCategoriesOptions, getReasonsOptions } from '@pages/InvoiceDetails/utils';

import { Box } from '@mui/material';

const MissingDetailsDropdowns: FC = () => {
  const { t } = useTranslation();
  const { categoriesOptions, supportCategory, invoiceData, reasonOptions, isReason } = useInvoiceDetailsNewContext();

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {!invoiceData?.abn && !isReason && (
        <AlertDropdown
          title={t('dashboard.abnMissingReason')}
          placeholder={t('dashboard.abnSelectReason')}
          options={getReasonsOptions(reasonOptions)}
          name={MISSING_REASON_FIELD}
        />
      )}
      {!supportCategory && (
        <AlertDropdown
          title={t('dashboard.invoiceDetails.supportCategoryDescription')}
          placeholder={t('dashboard.invoiceDetails.selectSupportCategory')}
          options={getCategoriesOptions(categoriesOptions)}
          name={MISSING_CATEGORY_FIELD}
        />
      )}
    </Box>
  );
};

export default memo(MissingDetailsDropdowns);
