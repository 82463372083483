import { FC, memo } from 'react';
import { PaginatedInvoiceListRead } from '@api/api';
import { useBreakpoints } from '@hooks/useBreakpoints';
import DateSelectorGroup from '@pages/Invoices/components/CalendarView/DateSelectorGroup';
import InvoicesListDesktop from '@pages/Invoices/components/CalendarView/InvoicesListDesktop';
import InvoiceListMobile from '@pages/Invoices/components/CalendarView/InvoicesListMobile';
import MonthSelector from '@pages/Invoices/components/CalendarView/MonthSelector';
import ParticipantAutocomplete from '@pages/Invoices/components/CalendarView/ParticipantAutocomplete';
import { useInvoicesContext } from '@pages/Invoices/context';
import { SELECTOR } from '@pages/Invoices/enums';

import { Box, LinearProgress } from '@mui/material';

const CalendarView: FC<{ invoicesData?: PaginatedInvoiceListRead; isLoading: boolean }> = ({
  invoicesData,
  isLoading,
}) => {
  const { downMd, downSm } = useBreakpoints();
  const {
    selectedMonth,
    handleChangeMonth,
    activeParticipants,
    handleParticipantChange,
    selectedParticipants,
    setSelectedMonth,
    hasMultipleActiveParticipants,
    handlePickDate,
    pickedDate,
    setPickedDate,
  } = useInvoicesContext();

  return (
    <Box
      width="100%"
      height="100%"
      p={{
        xs: '20px 16px',
        sm: '30px 20px',
        md: '40px 30px',
        lg: '40px 80px',
      }}
      position="relative"
    >
      <Box display={downSm ? 'block' : 'flex'} justifyContent="space-between" gap={downSm ? 0 : 2} height={58}>
        <Box
          display="flex"
          gap={downSm ? 0 : 2}
          justifyContent={downMd ? 'space-between' : 'start'}
          width="100%"
          alignItems="center"
        >
          <MonthSelector
            unformattedMonthYear={selectedMonth}
            onNext={() => {
              handleChangeMonth(SELECTOR.NEXT);
              setPickedDate(null);
            }}
            onPrev={() => {
              handleChangeMonth(SELECTOR.PREV);
              setPickedDate(null);
            }}
          />

          {!downSm && <DateSelectorGroup handleSetMonth={setSelectedMonth} handlePickDate={handlePickDate} />}
        </Box>

        {hasMultipleActiveParticipants && !downMd && (
          <Box minWidth="300px">
            <ParticipantAutocomplete
              activeParticipants={activeParticipants}
              selectedParticipants={selectedParticipants}
              handleParticipantChange={handleParticipantChange}
            />
          </Box>
        )}
      </Box>
      {hasMultipleActiveParticipants && downMd && (
        <Box mt={3}>
          <ParticipantAutocomplete
            activeParticipants={activeParticipants}
            selectedParticipants={selectedParticipants}
            handleParticipantChange={handleParticipantChange}
          />
        </Box>
      )}

      {isLoading && (
        <Box width="100%" mt={1}>
          <LinearProgress />
        </Box>
      )}
      {invoicesData && !isLoading && downMd && (
        <InvoiceListMobile
          invoicesData={invoicesData}
          activeParticipants={activeParticipants}
          selectedMonth={selectedMonth}
          pickedDate={pickedDate}
        />
      )}
      {invoicesData && !isLoading && !downMd && (
        <InvoicesListDesktop
          invoicesData={invoicesData}
          activeParticipants={activeParticipants}
          selectedMonth={selectedMonth}
          pickedDate={pickedDate}
        />
      )}
    </Box>
  );
};

export default memo(CalendarView);
