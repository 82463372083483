import { ICON_TYPE } from '@pages/InvoiceDetails/components/TasksTab/constants';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import EmailIcon from '@mui/icons-material/Email';
import PaidIcon from '@mui/icons-material/Paid';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import UploadIcon from '@mui/icons-material/Upload';

export const ICONS_SVG_MAP = {
  [ICON_TYPE.custom_event]: DashboardCustomizeIcon,
  [ICON_TYPE.claim_submitted]: DomainVerificationIcon,
  [ICON_TYPE.invoice_paid]: CheckCircleIcon,
  [ICON_TYPE.ndis_funds_received]: PaidIcon,
  [ICON_TYPE.invoice_emailed]: EmailIcon,
  [ICON_TYPE.payment_due_date]: PriorityHighIcon,
  [ICON_TYPE.invoice_uploaded]: UploadIcon,
};
