export enum Indicators {
  DETAILS = 'DETAILS',
  TASKS = 'TASKS',
  NOTES = 'NOTES',
}

export enum IconStatus {
  ACTION = 'ACTION',
  INFO = 'INFO',
  PREFILLED = 'PREFILLED',
  NONE = 'NONE',
  DOCUMENT = 'DOCUMENT',
  MISSING_DOCUMENT = 'MISSING_DOCUMENT',
}
