import { FC, memo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '@pages/Invoices/enums';
import { StyledAutocompleteParticipantTextInputField } from '@pages/Invoices/styled';
import { ExtendedActiveParticipant } from '@pages/Invoices/types';
import { getChipColors } from '@pages/Invoices/utils';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CircleIcon from '@mui/icons-material/Circle';
import StarIcon from '@mui/icons-material/Star';
import { Box, Chip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import AutocompletePaper from '@components/AutocompletePaper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

interface ParticipantAutocompleteProps {
  handleParticipantChange: (_event: SyntheticEvent, newSelectedParticipants: ExtendedActiveParticipant[]) => void;
  activeParticipants: ExtendedActiveParticipant[] | [];
  selectedParticipants: ExtendedActiveParticipant[] | [];
}

const ParticipantAutocomplete: FC<ParticipantAutocompleteProps> = ({
  handleParticipantChange,
  activeParticipants,
  selectedParticipants,
}) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple
      disableClearable
      fullWidth
      options={activeParticipants}
      disableCloseOnSelect
      getOptionLabel={option => option.first_name}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectedParticipants.some(sp => sp.id === option.id)}
            />
            {`${option.first_name ? option.first_name : ''} ${option.last_name ? option.last_name : ''}`}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      PaperComponent={AutocompletePaper}
      value={selectedParticipants}
      onChange={handleParticipantChange}
      renderInput={params => (
        <StyledAutocompleteParticipantTextInputField
          {...params}
          variant="outlined"
          label={t('dashboard.viewByParticipant')}
          onKeyDown={(event: any) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
        />
      )}
      renderTags={(value: ExtendedActiveParticipant[], getTagProps) =>
        value.map((option: ExtendedActiveParticipant, index: number) => {
          const isLast = option.role === ICONS.CIRCLE;
          // const isLast = option.id > activeParticipants[0].id;

          return activeParticipants.find(x => x.id === option.id) ? (
            <Chip
              variant="filled"
              size="medium"
              label={
                <Box display="flex" alignItems="center" gap="2px">
                  {isLast ? <CircleIcon fontSize="small" /> : <StarIcon fontSize="small" />}
                  <Typography fontSize={13}>{` ${option.first_name}`}</Typography>
                </Box>
              }
              sx={{
                color: '#000000DE',
                backgroundColor: getChipColors(isLast).backgroundColor,
                border: `1px solid ${getChipColors(isLast).borderColor}`,
                outline: `1px solid ${getChipColors(isLast).borderColor}`,
                '& svg': { color: getChipColors(isLast).borderColor },
              }}
            />
          ) : (
            <Chip
              variant="filled"
              size="medium"
              color="primary"
              label={option.first_name}
              {...getTagProps({ index })}
            />
          );
        })
      }
    />
  );
};

export default memo(ParticipantAutocomplete);
